import React from "react";

import { Link } from "react-router-dom";

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Button, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 54,
    borderRadius: 10,
    padding: "6px 0",
    position: "relative",
  },
  label: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "normal",
    lineHeight: "1.2rem",
  },
  index: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    marginLeft: ".5rem",
    width: 25,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontWeight: "bold",
  },
  disabled: {},
}));

const themeGreen = createTheme({
  palette: {
    primary: {
      main: "#00CC99",
      contrastText: "#ffffff",
    },
  },
});

const themePrimaryDark = createTheme({
  palette: {
    primary: {
      main: "#CC3300",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const themeGrey = createTheme({
  palette: {
    primary: {
      main: "#666666",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default function DefaultButton(props) {
  const classes = useStyles();
  const {
    text,
    color,
    isreactlink,
    component,
    href,
    hasFreeRequest,
    children,
  } = props;

  const matchesIphone5 = useMediaQuery("(max-width:321px)");
  const matchesMotoG4 = useMediaQuery("(min-width: 360px)");
  const matchesPixel2 = useMediaQuery("(min-width: 410px)");
  const matches = useMediaQuery("(min-width: 500px)");

  const checkDevices = () => {
    if (matchesIphone5) {
      return 28;
    } else if (matchesMotoG4) {
      return 36;
    } else if (matchesPixel2) {
      return 40;
    }
  };

  const checkTextWidth = () => {
    if (matches) {
      return text;
    }

    if (text && text.length >= checkDevices()) {
      return (
        <>
          <div className="marquee">
            <span>{text}</span>
          </div>
          <div className="marquee marquee2">
            <span>{text}</span>
          </div>
        </>
      );
    }
    return text;
  };

  if (color !== "primary" && color !== "secondary") {
    return (
      <ThemeProvider
        theme={
          color === "green"
            ? themeGreen
            : color === "primaryDark"
            ? themePrimaryDark
            : color === "grey"
            ? themeGrey
            : null
        }
      >
        <Button
          {...props}
          classes={{
            root: classes.root,
            label: classes.label,
            disabled: classes.disabled,
          }}
          component={isreactlink ? Link : component}
          to={isreactlink ? href : undefined}
          href={isreactlink ? undefined : href}
          color="primary"
        >
          {checkTextWidth()}
          {children}
        </Button>
      </ThemeProvider>
    );
  }

  return (
    <Button
      {...props}
      classes={{
        root: classes.root,
        label: classes.label,
        disabled: classes.disabled,
      }}
      component={isreactlink ? Link : component}
      to={isreactlink ? href : undefined}
      href={isreactlink ? undefined : href}
    >
      {checkTextWidth()}
      {hasFreeRequest && <div className={classes.index}>{hasFreeRequest}</div>}
      {children}
    </Button>
  );
}
