import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    const analytics = getAnalytics();
    logEvent(analytics, "page_view", { page_path });
  }, [location]);
  return null;
}
