import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  fab: {
    width: 67,
    height: 67,
    backgroundColor: (props) =>
      props.bgColor ? props.bgColor : theme.palette.secondary.main,
    backgroundImage: (props) =>
      props.isNew
        ? `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
        : null,
    borderRadius: 15,
    boxShadow: `0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)`,
  },
  text: {
    marginTop: ".4rem",
    fontSize: 12,
    color: (props) =>
      props.isWhiteText
        ? theme.palette.secondary.main
        : theme.palette.secondary.grey,
  },
  disabled: {
    opacity: 0.6,
  },
}));

const SquareButton = (props) => {
  const classes = useStyles(props);

  const {
    disabled,
    onClick,
    component,
    href,
    icon,
    text,
    style,
    tooltipExists,
    tooltipTitle,
    placement,
  } = props;

  return (
    <div
      className={`${classes.root} square-btn ${
        disabled ? classes.disabled : null
      }`}
      style={style}
    >
      {tooltipExists ? (
        <Tooltip title={tooltipTitle} placement={placement}>
          <span>
            <Fab
              onClick={onClick}
              disabled={disabled}
              className={classes.fab}
              component={component === "link" ? Link : component}
              to={component === "link" ? href : undefined}
            >
              {icon}
            </Fab>
          </span>
        </Tooltip>
      ) : (
        <Fab
          onClick={onClick}
          disabled={disabled}
          className={classes.fab}
          component={component === "link" ? Link : component}
          to={component === "link" ? href : undefined}
        >
          {icon}
        </Fab>
      )}

      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default SquareButton;
