import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Dialog,
  makeStyles,
  IconButton,
  Backdrop,
  styled,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

import { history } from "../../../store/history";
import { setActiveTutorial, setOpenAddSign } from "../../../store/actions";

import DefaultButton from "../../Comms/Buttons/Default";

import ArrowBack from "../../../Images/arrow-back.svg";
import ArrowForward from "../../../Images/arrow-forward.svg";
import ArrowBackGrey from "../../../Images/arrow-back-grey.svg";
import ArrowForwardGrey from "../../../Images/arrow-forward-grey.svg";
import { HELP, HOME } from "../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "1600 !important",
  },
  paper: {
    padding: "1.5rem 2rem",
    borderRadius: 29,
    maxWidth: 400,
    position: "absolute",
    margin: 0,
    top: (props) =>
      props.activeTutorial
        ? props.activeTutorial.popupPosition
          ? props.activeTutorial.popupPosition.top || "inherit"
          : "inherit"
        : "inherit",
    left: (props) =>
      props.activeTutorial
        ? props.activeTutorial.popupPosition
          ? props.activeTutorial.popupPosition.left
          : "inherit"
        : "inherit",
    [theme.breakpoints.down("sm")]: {
      left: (props) =>
        props.activeTutorial
          ? props.activeTutorial.popupPosition
            ? props.activeTutorial.popupPosition.left
            : 0
          : 0,
      margin: "0 32px",
    },
  },
  text: {
    fontSize: 17,
    fontWeight: 500,
    color: "#707070",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  stepperRoot: {
    maxWidth: 180,
  },
  stepsCounter: {
    fontSize: 21,
    fontWeight: 600,
    color: "#707070",
  },
  icon: {
    color: "#707070",
  },
  iconBtn: {
    width: 43.38,
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    left: 10,
    width: 25,
    height: 25,
    background: "#707070",
    padding: 0,
    "&:hover": {
      background: "#707070",
    },
  },
  closeIcon: {
    color: theme.palette.secondary.main,
  },
}));

const Stepper = ({ activeTutorial, handleGoBack, handleGoForward }) => {
  const classes = useStyles();
  const activePage = activeTutorial ? activeTutorial.activeIndex : null;
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-evenly"
      alignItems="center"
      className={classes.stepperRoot}
    >
      <IconButton
        aria-label="voltar"
        onClick={handleGoBack}
        disabled={activePage === 0}
        className={classes.iconBtn}
      >
        <img
          src={activePage === 0 ? ArrowBackGrey : ArrowBack}
          className={classes.icon}
        />
      </IconButton>
      <Typography className={classes.stepsCounter}>
        {activePage + 1}/{activeTutorial ? activeTutorial.pages.length : ""}
      </Typography>
      <IconButton
        aria-label="próximo"
        onClick={handleGoForward}
        disabled={
          activeTutorial
            ? activePage === activeTutorial.pages.length - 1
            : false
        }
        className={classes.iconBtn}
      >
        <img
          src={
            activeTutorial
              ? activePage === activeTutorial.pages.length - 1
                ? ArrowForwardGrey
                : ArrowForward
              : null
          }
          className={classes.icon}
        />
      </IconButton>
    </Grid>
  );
};

export default function TutorialPopup() {
  const activeTutorial = useSelector((state) => state.activeTutorial);
  const classes = useStyles({ activeTutorial });
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const popupRef = useRef();

  useEffect(() => {
    if (activeTutorial) {
      const { state, page } = activeTutorial.pages[activeTutorial.activeIndex];
      if (page !== window.location.pathname) {
        history.replace({
          pathname: page,
          state: state || null,
        });
      }
      if (!activeTutorial.popupPosition && activeTutorial.ref) {
        changePopupPosition();
      }
    }
  }, [activeTutorial]);

  const changePopupPosition = () => {
    const spacing = 10;
    const left = getRef("left") - spacing;
    const top = getRef("top") - spacing;
    const width = getRef("width") + spacing * 2;
    const height = getRef("height") + spacing * 2;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const popupPadding = 24;

    if (!popupRef.current) return;
    const popupHeight = popupRef.current.offsetHeight;
    const popupWidth = popupRef.current.offsetWidth;

    var newTop;
    var newLeft;

    if (matchesMobile) {
      // check mobile breakpoints
      if (top + height >= windowHeight - popupHeight) {
        if (top + height >= windowHeight) {
          if (top > 512 && top + height > windowHeight) {
            newTop = top - (popupHeight + spacing);
          } else if (top >= 560 && popupHeight > 340) {
            newTop = top - (popupHeight + spacing);
          } else if (top > 200) {
            newTop = spacing;
          } else {
            newTop = "";
          }
        } else if (top >= popupHeight + spacing * 2) {
          newTop = top - popupHeight - spacing;
        } else if (popupHeight - popupPadding * 2 < top) {
          newTop = spacing;
        } else {
          newTop = windowHeight - popupHeight - spacing;
        }
      } else {
        newTop = top + height + spacing;
      }
      newLeft = "";
    } else {
      // check desktop breakpoints
      newLeft = left + width + spacing;
      if (left + width >= windowWidth - (popupWidth + spacing)) {
        newLeft = left - (popupWidth + spacing);
      }
      if (top + height >= windowHeight) {
        newTop = windowHeight - popupHeight - spacing;
      } else if (top <= popupHeight / 2) {
        if (top >= 0) {
          newTop = top;
        } else {
          newTop = spacing;
        }
      } else if (top > 518 && top + popupHeight > windowHeight) {
        newTop = top + height - popupHeight + spacing;
      } else {
        newTop = top + height / 2 - popupHeight / 2;
      }
    }

    dispatch(
      setActiveTutorial({
        ...activeTutorial,
        popupPosition: {
          top: newTop,
          left: newLeft,
        },
      })
    );
  };

  const handleGoBack = () => {
    dispatch(
      setActiveTutorial({
        ...activeTutorial,
        activeIndex: activeTutorial.activeIndex - 1,
        ref: null,
        popupPosition: null,
      })
    );
  };
  const handleGoForward = () => {
    dispatch(
      setActiveTutorial({
        ...activeTutorial,
        activeIndex: activeTutorial.activeIndex + 1,
        ref: null,
        popupPosition: null,
      })
    );
  };
  const handleClose = (e, reason) => {
    if (reason === "escapeKeyDown") return;
    if (reason === "backdropClick") {
      var x = e.clientX;
      var y = e.clientY;
      var left = getRef("left") - 10;
      var top = getRef("top") - 10;
      var width = getRef("width") + 20;
      var height = getRef("height") + 20;

      if (x >= left && x < left + width && y >= top && y < top + height) {
        if (activeTutorial.activeIndex !== activeTutorial.pages.length - 1) {
          handleGoForward();
        }
      }

      return;
    }
    closeTutorial();
  };

  const closeTutorial = () => {
    var route = HOME;
    if (activeTutorial.start === "help") {
      route = HELP;
    }
    dispatch(setOpenAddSign(false));
    dispatch(setActiveTutorial(null));
    history.replace(route);
  };

  const getRef = (type) => {
    return activeTutorial
      ? activeTutorial.ref
        ? activeTutorial.ref[type]
        : 0
      : 0;
  };

  const TutorialBackdrop = styled(Backdrop)({
    backgroundColor: "inherit",
    "&::after": {
      content: '""',
      position: "absolute",
      borderRadius: 50,
      left: getRef("left") - 10,
      top: getRef("top") - 10,
      width: getRef("width") + 20,
      height: getRef("height") + 20,
      boxShadow: "0px 0px 0px 1000vw rgba(0, 0, 0, 0.5)",
    },
  });

  const checkLastPage = () => {
    if (activeTutorial) {
      return activeTutorial.pages.length === activeTutorial.activeIndex + 1;
    }
    return false;
  };

  const checkPageComponent = () => {
    if (activeTutorial) {
      return activeTutorial.ref;
    }
    return false;
  };

  const getText = () => {
    if (activeTutorial) {
      const { text, textDesktop } =
        activeTutorial.pages[activeTutorial.activeIndex];
      if (textDesktop) {
        return matchesMobile ? text : textDesktop;
      } else {
        return text;
      }
    } else {
      return "";
    }
  };

  return (
    <Dialog
      open={activeTutorial ? true : false}
      classes={{
        paper: classes.paper,
        root: classes.root,
      }}
      onClose={handleClose}
      BackdropComponent={checkPageComponent() ? TutorialBackdrop : undefined}
      PaperProps={{
        ref: popupRef,
      }}
      keepMounted
    >
      <Grid container justifyContent="center" spacing={checkLastPage() ? 2 : 0}>
        <Stepper
          activeTutorial={activeTutorial}
          handleGoBack={handleGoBack}
          handleGoForward={handleGoForward}
        />
        <Grid item xs={12}>
          <Typography className={classes.text}>{getText()}</Typography>
        </Grid>
        {checkLastPage() ? (
          <Grid item xs={12}>
            <DefaultButton
              variant="outlined"
              text="Concluir"
              onClick={closeTutorial}
            />
          </Grid>
        ) : null}
      </Grid>
      <IconButton
        aria-label="fechar"
        className={classes.closeBtn}
        onClick={handleClose}
      >
        <CloseOutlined className={classes.closeIcon} />
      </IconButton>
    </Dialog>
  );
}
