import { CircularProgress, TextField, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: (props) => (props.isLoading ? "flex-start" : "center"),
  },
  progress: {
    margin: "0 .3rem",
    aspectRatio: "1/1",
  },
}));

const DefaultField = (props) => {
  const {
    autoComplete,
    autoFocus,
    format,
    type,
    isWhite,
    label,
    value,
    onChange,
    error,
    helperText,
    required,
    endAdornment,
    onBlur,
    disabled,
    shrinkLabel,
    maxLength,
    multiline,
    minRows,
    isLoading,
    name,
  } = props;
  const classes = useStyles({ isLoading });

  const ref = useRef();

  useEffect(() => {
    if (disabled !== undefined && !disabled) {
      ref.current.focus();
    }
  }, [disabled]);

  return (
    <TextField
      fullWidth
      color={isWhite ? "secondary" : "primary"}
      className={`default-text-field ${isWhite ? "white" : ""}`}
      name={name}
      InputProps={{
        endAdornment: isLoading ? (
          <CircularProgress size={25} className={classes.progress} />
        ) : (
          endAdornment
        ),
        disableUnderline: true,
        classes: {
          root: classes.root,
        },
      }}
      InputLabelProps={{
        shrink: shrinkLabel,
      }}
      inputRef={ref}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      format={format}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={error}
      multiline={multiline}
      minRows={minRows}
      helperText={error ? helperText : undefined}
      required={required}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

export default DefaultField;
