const DateFormat = ({ date, isString }) => {
  if (!date) {
    return "";
  }
  if (isString) {
    var d;
    if (date.seconds !== undefined) {
      d = date.toDate();
    } else {
      d = date;
    }
    var month = [];
    month[0] = "Janeiro";
    month[1] = "Fevereiro";
    month[2] = "Março";
    month[3] = "Abril";
    month[4] = "Maio";
    month[5] = "Junho";
    month[6] = "Julho";
    month[7] = "Agosto";
    month[8] = "Setembro";
    month[9] = "Outubro";
    month[10] = "Novembro";
    month[11] = "Dezembro";
    return `${d.getDate()} ${month[d.getMonth()]} ${d.getFullYear()}`;
  }
  var dateNotString;
  if (date.seconds !== undefined) {
    dateNotString = date.toDate();
  } else {
    dateNotString = date;
  }
  return dateNotString.toLocaleDateString("pt-br");
};

export default DateFormat;
