import React from "react";
import SummarizeDoc from "../../Components/SummarizeDoc";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.darkGrey,
  },
}));

export default function TestPage() {
  const classes = useStyles();
  return (
    <div className="feed-grid">
      <div style={{ padding: "1.5rem" }}>
        <Typography
          variant="h4"
          align="center"
          className={classes.title}
          gutterBottom
        >
          Teste nossa nova ferramenta usando o GPT-3
        </Typography>
        <Typography
          variant="h6"
          align="center"
          className={classes.title}
          gutterBottom
        >
          Nenhum crédito será cobrado da sua conta!
        </Typography>
        <div style={{ padding: "1rem" }} />

        <SummarizeDoc />
      </div>
    </div>
  );
}
