import React, {
  useState,
  useContext,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import { Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FirebaseAnalytics from "./firebase/FirebaseAnalytics";
import { FirebaseContext } from "./firebase";
import * as ROUTES from "./constants/routes";
import { history } from "./store/history";
import {
  changePageIndex,
  onScroll,
  resetSettingSign,
  setErrorAlert,
  setOpenAddSign,
  setOpenConfetti,
  setSignatureCreation,
  setSuggestSignInPopup,
  setVerifyEmailPopup,
} from "./store/actions";
import { addRefAT } from "./store/actions";

import {
  Grid,
  useMediaQuery,
  Button,
  Snackbar,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import AutomationIcon from "./Images/automation.svg";

import Header from "./Components/Header";
import Navbar from "./Components/Navbar";
import QuickActions from "./Components/QuickActions";
import AddSign from "./Pages/Signature/components/drawers/AddSign";
import SquareButton from "./Components/Comms/Buttons/Square";

import IntroPopup from "./Components/Comms/Dialogs/AppIntro";
import TutorialPopup from "./Components/Comms/Dialogs/Tutorial";
import VerifyEmailPopup from "./Components/Comms/Dialogs/VerifyEmail";
import ActivatedCodePopup from "./Components/Comms/Dialogs/ActivatedCode";

import AuthPage from "./Pages/Authentication/Auth";
import DefaultAlert from "./Components/Comms/Alert";
import Confetti from "react-confetti";
import Chat from "./Components/Chat";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import SuggestSignInPopup from "./Components/Comms/Dialogs/SuggestSignInPopup";
import SummarizeDoc from "./Components/SummarizeDoc";
import TestPage from "./Pages/Test";
import TestSummarizePage from "./Pages/Test/TestSummarize";
import TestSign2Page from "./Pages/Test/TesteSign2";
import NewReleases from "./Components/NewReleases";
import { v4 as uuid } from "uuid";
import AcceptTermsDialog from "./Components/Comms/Dialogs/AcceptTerms";

// Before Sign in
const IntroPage = React.lazy(() => import("./Pages/BeforeSignIn/Intro"));
const SignInPage = React.lazy(() => import("./Pages/BeforeSignIn/SignIn"));
const SignUpPage = React.lazy(() => import("./Pages/BeforeSignIn/SignUp"));
const PoliceIntroPage = React.lazy(() =>
  import("./Pages/BeforeSignIn/PoliceIntro")
);
const TermsIntroPage = React.lazy(() =>
  import("./Pages/BeforeSignIn/TermsIntro")
);

// Recover password
const VerifyEmailPage = React.lazy(() =>
  import("./Pages/RecoverPassword/VerifyEmail")
);
const ForgotPasswordPage = React.lazy(() =>
  import("./Pages/RecoverPassword/ForgotPassword")
);
const ReconfigurePasswordPage = React.lazy(() =>
  import("./Pages/RecoverPassword/ReconfigurePassword")
);
const VerifyCompletePage = React.lazy(() =>
  import("./Pages/RecoverPassword/VerifyComplete")
);

// Change password
const VerifyCompleteInternPage = React.lazy(() =>
  import("./Pages/ChangePassword/VerifyComplete")
);

// Documentation
const PrivacyPolicePage = React.lazy(() =>
  import("./Pages/Documentation/PrivacyPolice")
);
const TermsOfServicePage = React.lazy(() =>
  import("./Pages/Documentation/TermsOfService")
);

// Feedback
const FeedbackPage = React.lazy(() => import("./Pages/Feedback/Feedback"));
const CompletedFeedbackPage = React.lazy(() =>
  import("./Pages/Feedback/CompletedFeedback")
);

// Feeds
const HomePage = React.lazy(() => import("./Pages/Feeds/Home"));
const ServicesPage = React.lazy(() => import("./Pages/Feeds/Services"));
const HistoryPage = React.lazy(() => import("./Pages/Feeds/History"));
const SharePage = React.lazy(() => import("./Pages/Feeds/Share"));
const MentoringPage = React.lazy(() => import("./Pages/Feeds/Mentoring"));

// Documents
const DocumentPage = React.lazy(() => import("./Pages/Documents/Document"));
const SharedDocumentPage = React.lazy(() =>
  import("./Pages/Documents/SharedDocument")
);
const DocumentAlreadyFinishedPage = React.lazy(() =>
  import("./Pages/Documents/DocumentAlreadyFinished")
);
const SharedDocUserPage = React.lazy(() =>
  import("./Pages/Documents/SharedDocument/User")
);

// Request process
const ServicePage = React.lazy(() => import("./Pages/RequestProcess/Service"));
const RequestPage = React.lazy(() => import("./Pages/RequestProcess/Request"));
const StepPage = React.lazy(() => import("./Pages/RequestProcess/Step"));
const TaskPage = React.lazy(() => import("./Pages/RequestProcess/Task"));
const RegistryPage = React.lazy(() =>
  import("./Pages/RequestProcess/Registry")
);
const BudgetPage = React.lazy(() => import("./Pages/RequestProcess/Budget"));
const ActionPlanPage = React.lazy(() =>
  import("./Pages/RequestProcess/ActionPlan")
);
const SelectClassesPage = React.lazy(() =>
  import("./Pages/RequestProcess/SelectClassesRegistry")
);
const PaymentPage = React.lazy(() => import("./Pages/RequestProcess/Payment"));

// User data
const DataPage = React.lazy(() => import("./Pages/UserData/Data"));

// Invitation
const InvitePage = React.lazy(() => import("./Pages/Invitation/Invite"));
const InviteListPage = React.lazy(() =>
  import("./Pages/Invitation/InviteList")
);

// Payment
const MethodsPage = React.lazy(() => import("./Pages/Payment/Methods"));
const CreditsPage = React.lazy(() => import("./Pages/Payment/Credits"));
const PlansPage = React.lazy(() => import("./Pages/Payment/Plans"));
const AddMethodPage = React.lazy(() => import("./Pages/Payment/AddMethod"));

// Email verification
const EmailVerificationCompletePage = React.lazy(() =>
  import("./Pages/EmailVerification/EmailVerificationComplete")
);

// Help
const HelpPage = React.lazy(() => import("./Pages/Help"));
const TutorialsPage = React.lazy(() => import("./Pages/Help/Tutorials"));
const FaqPage = React.lazy(() => import("./Pages/Help/Faq"));
const FaqItemPage = React.lazy(() => import("./Pages/Help/Faq/Item"));

// Mentoring
const LessonPage = React.lazy(() => import("./Pages/Mentoring/Lesson"));
const LessonItemPage = React.lazy(() => import("./Pages/Mentoring/LessonItem"));
const ScorePage = React.lazy(() => import("./Pages/Mentoring/Score"));

// Parts
const AddOrEditPartPage = React.lazy(() =>
  import("./Pages/Parts/AddOrEditPart")
);
const SelectPartsPage = React.lazy(() => import("./Pages/Parts/SelectParts"));
const SelectPartPage = React.lazy(() => import("./Pages/Parts/SelectPart"));

// Signature
const SetSignaturePage = React.lazy(() =>
  import("./Pages/Signature/SetSignature")
);
const FinishedSignaturePage = React.lazy(() =>
  import("./Pages/Signature/FinishedSignature")
);
const OnlySignPage = React.lazy(() => import("./Pages/Signature/OnlySign"));
const SelectDocumentToSignPage = React.lazy(() =>
  import("./Pages/Signature/SelectDocumentToSign")
);
const SelectAutomationToSignPage = React.lazy(() =>
  import("./Pages/Signature/SelectAutomationToSign")
);

// Signature 2.0
const SettingSignPage = React.lazy(() => import("./Pages/Signature2/Setting"));
const SigningPage = React.lazy(() => import("./Pages/Signature2/Signing"));
const DraftsPage = React.lazy(() => import("./Pages/Signature2/Drafts"));

// Automations
const AutomationPage = React.lazy(() =>
  import("./Pages/Automations/Automation")
);
const FinishedAutomationPage = React.lazy(() =>
  import("./Pages/Automations/FinishedAutomation")
);
const FillAutomationPage = React.lazy(() =>
  import("./Pages/Automations/FillAutomation")
);
const CustomAutomationsPage = React.lazy(() =>
  import("./Pages/Automations/Custom/CustomAutomations")
);
const ContractModelPage = React.lazy(() =>
  import("./Pages/Automations/Custom/ContractModel")
);
const EditCustomAutomationPage = React.lazy(() =>
  import("./Pages/Automations/Custom/EditCustomAutomation")
);

// Tags
const TagEmailPage = React.lazy(() => import("./Pages/Tags/Email"));

// Delete account
const DeleteAccountPage = React.lazy(() => import("./Pages/Account/Delete"));

const useStyles = makeStyles((theme) => ({
  uhGrid: {
    backgroundColor: theme.palette.bg,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `-15px -43px 10px -17px ${theme.palette.secondary.grey}, -15px 552px 10px -17px ${theme.palette.secondary.grey}`,
  },
  backdrop: {
    zIndex: 1301,
    backgroundColor: theme.palette.primary.main,
    display: "grid",
    placeItems: "center",
  },
  paperBackdrop: {
    borderRadius: "50px",
    width: "75%",
    padding: "3rem 0",
    maxHeight: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textBackdrop: {
    color: theme.palette.secondary.grey,
    width: "70%",
    margin: "0 auto",
    marginTop: "2rem",
    fontWeight: "bold",
    fontSize: 22,
  },
  iconBackdrop: {
    fontSize: "4rem",
  },
  qaGrid: {
    backgroundColor: theme.palette.bg,
    padding: "1rem",
  },
  gridBeforeSignIn: {
    height: "100%",
  },
}));

export default function App() {
  // MUI
  const classes = useStyles();
  const matchesDesktop = useMediaQuery("(min-width:960px)");
  const theme = useTheme();

  const { auth, api } = useContext(FirebaseContext);

  const checkLocation = (location) => {
    const { pathname } = location;
    const pathnameSplited = pathname.split("/");
    if (
      pathname === ROUTES.INTRO ||
      pathname === ROUTES.SIGN_IN ||
      pathname === ROUTES.SIGN_UP ||
      pathnameSplited[1] === "registrar" ||
      pathname === ROUTES.TERMS_OF_SERVICE_INTRO ||
      pathname === ROUTES.PRIVACY_POLICE_INTRO ||
      pathname === ROUTES.FORGOT_PASSWORD ||
      pathname === ROUTES.RECONFIGURE_PASSWORD ||
      pathname === ROUTES.VERIFY_EMAIL ||
      pathname === ROUTES.VERIFY_COMPLETE ||
      pathname === ROUTES.EMAIL_VERIFICATION_COMPLETE_PAGE ||
      pathname === ROUTES.AUTH ||
      pathnameSplited[1] === "assinar"
    ) {
      return "";
    } else if (pathname === ROUTES.HOME || pathname === ROUTES.MENTORING) {
      return "grid";
    } else if (
      pathname === ROUTES.SERVICES ||
      pathname === ROUTES.HISTORY ||
      pathname === ROUTES.SHARE
    ) {
      return "grid-with-tabs";
    } else {
      return `grid-default${
        pathnameSplited[1] === "preencher-automacao" ||
        pathnameSplited[1] === "preencher-automacao-compartilhada"
          ? " orange"
          : ""
      }`;
    }
  };

  const [classGrid, setClassGrid] = useState(checkLocation(window.location));
  const [value, setValue] = useState(window.location.pathname);
  const [openQA, setOpenQA] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeTutorial = useSelector((state) => state.activeTutorial);

  const loader = document.querySelector(".loader");
  const hideLoader = () => loader.classList.add("loader--hide");

  useLayoutEffect(() => {
    // Listening to authentication change
    const unsubscribe = onAuthStateChanged(auth, async function (user) {
      if (user) {
        await api.fetchData(user);
      } else {
        const { pathname } = window.location;
        const pathnameSplited = pathname.split("/");
        if (
          pathname !== ROUTES.INTRO &&
          pathname !== ROUTES.SIGN_IN &&
          pathname !== ROUTES.SIGN_UP &&
          pathnameSplited[1] !== "registrar" &&
          pathname !== ROUTES.TERMS_OF_SERVICE_INTRO &&
          pathname !== ROUTES.PRIVACY_POLICE_INTRO &&
          pathname !== ROUTES.FORGOT_PASSWORD &&
          pathname !== ROUTES.RECONFIGURE_PASSWORD &&
          pathname !== ROUTES.VERIFY_EMAIL &&
          pathname !== ROUTES.VERIFY_COMPLETE &&
          pathname !== ROUTES.EMAIL_VERIFICATION_COMPLETE_PAGE &&
          pathname !== ROUTES.AUTH &&
          pathname !== ROUTES.TAG_EMAIL &&
          pathnameSplited[1] !== "assinar"
        ) {
          const isTask =
            pathnameSplited[1] === "requisicao" &&
            pathnameSplited[3] === "etapa" &&
            pathnameSplited[5] === "tarefa";
          if (
            pathnameSplited[1] === "preencher-automacao-compartilhada" ||
            pathnameSplited[1] === "definir-assinatura-compartilhada" ||
            pathnameSplited[1] === "assinar2" ||
            pathnameSplited[1] === "assinatura-compartilhada" ||
            isTask
          ) {
            return signInAnonymously(auth)
              .then(() => {
                console.log("Signed in anonymously");
              })
              .catch((error) => {
                console.error("Error signing in anonymously", error);
              });
          }

          if (pathname !== ROUTES.HOME) {
            setAlertLogBefore(true);
            window.sessionStorage.setItem("pathToRedirect", pathname);
          }

          history.push(ROUTES.INTRO);
        }
      }
      if (value !== ROUTES.TAG_EMAIL) {
        hideLoader();
      }
    });

    // Listening history to change states
    const unlisten = history.listen((location) => {
      setValue(location.pathname);
      setClassGrid(checkLocation(location));
    });
    return () => {
      unlisten();
      unsubscribe();
    };
  }, []);

  const handleNewSign = () => {
    if (user.isAnon) {
      dispatch(setSuggestSignInPopup(true));
      return;
    }
    if (!user.emailVerified) {
      dispatch(setVerifyEmailPopup(true));
      return;
    }
    // New sign
    const locationSplitted = value.split("/");
    if (locationSplitted[1] === "configurar-assinatura") return;

    dispatch(resetSettingSign());
    history.push(`/configurar-assinatura/${uuid()}`);
  };
  const toggleDrawerQA = (newOpen) => {
    setOpenQA(newOpen);
  };

  const serviceWorker = useSelector((state) => state.serviceWorker);
  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const errorAlert = useSelector((state) => state.errorAlert);
  const handleCloseAlert = (state) => {
    dispatch(setErrorAlert(false));
  };

  const [alertLogBefore, setAlertLogBefore] = useState(false);
  const handleCloseLogBeforeAlert = () => {
    setAlertLogBefore(false);
  };

  const handleRedirectAutomations = () => {
    dispatch(onScroll(0, "services", 1));
    dispatch(changePageIndex("services", 1));
    history.push(ROUTES.SERVICES);
  };

  const navbarBackground = useSelector((state) => state.navbarBackground);
  const openConfetti = useSelector((state) => state.openConfetti);
  const handleCloseConfetti = () => {
    dispatch(setOpenConfetti(false));
  };

  // TUTORIAL IMPLEMENTATION
  const desktopQARef = useRef(null);
  const signBtnRef = useRef(null);

  const setRef = (reference) => {
    const { width, height, top, left } =
      reference.current.getBoundingClientRect();
    const ref = { width, height, top, left };

    if (!activeTutorial.ref) {
      dispatch(addRefAT(ref));
    }
  };

  useEffect(() => {
    if (activeTutorial && desktopQARef.current && matchesDesktop) {
      const { component } = activeTutorial.pages[activeTutorial.activeIndex];
      if (component === "signature.openDrawer") {
        setTimeout(() => {
          setRef(desktopQARef);
        }, 200);
      }
    }
  }, [activeTutorial, desktopQARef, matchesDesktop]);

  useEffect(() => {
    if (activeTutorial && signBtnRef.current && matchesDesktop) {
      const { component } = activeTutorial.pages[activeTutorial.activeIndex];
      if (component === "signature.sign_button") {
        setTimeout(() => {
          setRef(signBtnRef);
        }, 200);
      }
    }
  }, [activeTutorial, signBtnRef, matchesDesktop]);

  useEffect(() => {
    if (activeTutorial) {
      const { component } = activeTutorial.pages[activeTutorial.activeIndex];
      if (component === "signature.addSign_buttons") {
        dispatch(setOpenAddSign(true));

        dispatch(setSignatureCreation({}));
      } else {
        dispatch(setOpenAddSign(false));
      }
    }
  }, [activeTutorial]);

  return (
    <Router history={history}>
      {window.location.hostname !== "localhost" && <FirebaseAnalytics />}
      <div className={classGrid} style={{ height: "100%" }}>
        {classGrid.length ? (
          <>
            <div className="header-grid">
              <Header />
            </div>
            {matchesDesktop ? (
              <div className={`${classes.uhGrid} uh-grid`}>
                <Grid
                  container
                  className={`${classes.qaGrid} quick-actions-grid`}
                  ref={desktopQARef}
                >
                  <Grid item>
                    <SquareButton
                      icon={<img src={AutomationIcon} alt="Automações" />}
                      text="Automações"
                      component="button"
                      onClick={handleRedirectAutomations}
                    />
                  </Grid>
                  <Grid item ref={signBtnRef}>
                    <SquareButton
                      icon={
                        <EditOutlined
                          color="primary"
                          style={{ fontSize: "1.8rem" }}
                        />
                      }
                      text="Assinatura"
                      onClick={handleNewSign}
                    />
                  </Grid>
                  <Grid item>
                    <SummarizeDoc />
                  </Grid>
                </Grid>
                <Chat />
              </div>
            ) : null}
            <AcceptTermsDialog />

            <ActivatedCodePopup />
            <IntroPopup />
            <TutorialPopup />
            <VerifyEmailPopup />
            <SuggestSignInPopup />
            {openConfetti && (
              <Confetti
                colors={[theme.palette.primary.main]}
                className="app-confetti"
                numberOfPieces={1000}
                recycle={false}
                onConfettiComplete={handleCloseConfetti}
              />
            )}
          </>
        ) : null}

        <Switch>
          <React.Suspense fallback={<></>}>
            <Route exact path={ROUTES.INTRO} component={IntroPage} />
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route
              exact
              path={ROUTES.SIGN_UP_PROMO_CODE}
              component={SignUpPage}
            />
            <Route
              exact
              path={ROUTES.VERIFY_EMAIL}
              component={VerifyEmailPage}
            />
            <Route
              exact
              path={ROUTES.RECONFIGURE_PASSWORD}
              component={ReconfigurePasswordPage}
            />
            <Route
              exact
              path={ROUTES.FORGOT_PASSWORD}
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path={ROUTES.VERIFY_COMPLETE}
              component={VerifyCompletePage}
            />
            <Route exact path={ROUTES.ONLY_SIGN} component={OnlySignPage} />
            <Route
              exact
              path={ROUTES.TERMS_OF_SERVICE_INTRO}
              component={TermsIntroPage}
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICE_INTRO}
              component={PoliceIntroPage}
            />

            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route exact path={ROUTES.MENTORING} component={MentoringPage} />
            <Route exact path={ROUTES.SERVICES} component={ServicesPage} />
            <Route exact path={ROUTES.HISTORY} component={HistoryPage} />
            <Route exact path={ROUTES.SHARE} component={SharePage} />
            <Route exact path={ROUTES.SERVICE} component={ServicePage} />
            <Route exact path={ROUTES.REQUEST} component={RequestPage} />
            <Route exact path={ROUTES.STEP} component={StepPage} />
            <Route exact path={ROUTES.TASK} component={TaskPage} />
            <Route exact path={ROUTES.TASK_WITH_UID} component={TaskPage} />

            <Route exact path={ROUTES.OLD_REGISTRY} component={RegistryPage} />
            <Route exact path={ROUTES.OLD_DOCUMENT} component={DocumentPage} />
            <Route exact path={ROUTES.DOCUMENT} component={DocumentPage} />
            <Route
              exact
              path={ROUTES.AUTOMATION_ISOLATED}
              component={DocumentPage}
            />
            <Route
              exact
              path={ROUTES.SIGNATURE_ISOLATED}
              component={DocumentPage}
            />

            <Route
              exact
              path={ROUTES.DOCUMENT_INSIDE_SIGNATURE}
              component={DocumentPage}
            />
            <Route
              exact
              path={ROUTES.DOCUMENT_INSIDE_SHARED_DOC}
              component={DocumentPage}
            />
            <Route
              exact
              path={ROUTES.FINISHED_SIGNATURE}
              component={FinishedSignaturePage}
            />
            <Route
              exact
              path={ROUTES.FINISHED_SHARED_SIGNATURE}
              component={FinishedSignaturePage}
            />
            <Route
              exact
              path={ROUTES.FINISHED_AUTOMATION}
              component={FinishedAutomationPage}
            />
            <Route
              exact
              path={ROUTES.FINISHED_SHARED_AUTOMATION}
              component={FinishedAutomationPage}
            />
            <Route exact path={ROUTES.REGISTRY} component={RegistryPage} />
            <Route exact path={ROUTES.ACTION_PLAN} component={ActionPlanPage} />
            <Route exact path={ROUTES.BUDGET} component={BudgetPage} />
            <Route exact path={ROUTES.STEP_IN_AP} component={StepPage} />
            <Route exact path={ROUTES.TASK_IN_AP} component={TaskPage} />
            <Route
              exact
              path={ROUTES.TERMS_OF_SERVICE}
              component={TermsOfServicePage}
            />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICE}
              component={PrivacyPolicePage}
            />
            <Route exact path={ROUTES.FEEDBACK} component={FeedbackPage} />
            <Route
              exact
              path={ROUTES.COMPLETED_FEEDBACK}
              component={CompletedFeedbackPage}
            />
            <Route
              exact
              path={ROUTES.SELECT_PARTS}
              component={SelectPartsPage}
            />
            <Route exact path={ROUTES.SELECT_PART} component={SelectPartPage} />
            <Route
              exact
              path={ROUTES.WITHOUT_SELECT_PART}
              component={SelectPartPage}
            />
            <Route
              exact
              path={ROUTES.SELECT_PART_SIGNATURE}
              component={SelectPartPage}
            />
            <Route exact path={ROUTES.DATA} component={DataPage} />
            <Route exact path={ROUTES.ADD_PART} component={AddOrEditPartPage} />
            <Route
              exact
              path={ROUTES.EDIT_PART}
              component={AddOrEditPartPage}
            />
            <Route exact path={ROUTES.INVITE_PAGE} component={InvitePage} />
            <Route
              exact
              path={ROUTES.INVITE_LIST_PAGE}
              component={InviteListPage}
            />
            <Route
              exact
              path={ROUTES.PAYMENT_METHODS}
              component={MethodsPage}
            />
            <Route exact path={ROUTES.CREDITS} component={CreditsPage} />
            <Route exact path={ROUTES.SINGLE_CREDITS} component={CreditsPage} />

            <Route exact path={ROUTES.ADD_METHOD} component={AddMethodPage} />
            <Route exact path={ROUTES.PLANS} component={PlansPage} />
            <Route
              exact
              path={ROUTES.VERIFY_COMPLETE_INTERN_PAGE}
              component={VerifyCompleteInternPage}
            />
            <Route
              exact
              path={ROUTES.EMAIL_VERIFICATION_COMPLETE_PAGE}
              component={EmailVerificationCompletePage}
            />
            <Route exact path={ROUTES.AUTOMATION} component={AutomationPage} />
            <Route
              exact
              path={ROUTES.CUSTOM_AUTOMATIONS}
              component={CustomAutomationsPage}
            />
            <Route
              exact
              path={ROUTES.CONTRACT_MODEL}
              component={ContractModelPage}
            />
            <Route
              exact
              path={ROUTES.EDIT_CUSTOM_AUTOMATION}
              component={EditCustomAutomationPage}
            />
            <Route
              exact
              path={ROUTES.FILL_AUTOMATION}
              component={FillAutomationPage}
            />
            <Route
              exact
              path={ROUTES.FILL_SHARED_AUTOMATION}
              component={FillAutomationPage}
            />
            <Route
              exact
              path={ROUTES.SELECT_DOCUMENT_TO_SIGN}
              component={SelectDocumentToSignPage}
            />
            <Route
              exact
              path={ROUTES.SELECT_AUTOMATION_TO_SIGN}
              component={SelectAutomationToSignPage}
            />
            <Route
              exact
              path={ROUTES.SET_SIGNATURE_PAGE}
              component={SetSignaturePage}
            />
            <Route
              exact
              path={ROUTES.SET_SHARED_SIGNATURE_PAGE}
              component={SetSignaturePage}
            />

            {/* Signature 2.0 */}
            <Route
              exact
              path={ROUTES.SETTING_SIGNATURE_2}
              component={SettingSignPage}
            />
            <Route
              exact
              path={ROUTES.SIGNING_SIGNATURE_2}
              component={SigningPage}
            />
            <Route exact path={ROUTES.DRAFTS} component={DraftsPage} />
            <Route
              exact
              path={ROUTES.SELECT_PART_DRAFT_SIGN}
              component={SelectPartPage}
            />
            <Route exact path={ROUTES.TEST_SIGN2} component={TestSign2Page} />

            <Route exact path={ROUTES.SCORE} component={ScorePage} />
            <Route exact path={ROUTES.LESSON} component={LessonPage} />
            <Route exact path={ROUTES.LESSON_ITEM} component={LessonItemPage} />

            <Route exact path={ROUTES.HELP} component={HelpPage} />
            <Route exact path={ROUTES.FAQ} component={FaqPage} />
            <Route exact path={ROUTES.FAQ_ITEM} component={FaqItemPage} />

            <Route exact path={ROUTES.TUTORIALS} component={TutorialsPage} />

            <Route
              exact
              path={ROUTES.SHARED_AUTOMATION}
              component={SharedDocumentPage}
            />
            <Route
              exact
              path={ROUTES.SHARED_SIGNATURE}
              component={SharedDocumentPage}
            />
            <Route
              exact
              path={ROUTES.DOCUMENT_ALREADY_FINISHED}
              component={DocumentAlreadyFinishedPage}
            />
            <Route
              exact
              path={ROUTES.SHARED_AUTOMATION_USER}
              component={SharedDocUserPage}
            />
            <Route
              exact
              path={ROUTES.SHARED_SIGNATURE_USER}
              component={SharedDocUserPage}
            />
            <Route
              exact
              path={ROUTES.SELECT_CLASSES}
              component={SelectClassesPage}
            />
            <Route exact path={ROUTES.PAYMENT} component={PaymentPage} />
            <Route exact path={ROUTES.AUTH} component={AuthPage} />
            <Route exact path={ROUTES.TAG_EMAIL} component={TagEmailPage} />
            <Route
              exact
              path={ROUTES.DELETE_ACCOUNT}
              component={DeleteAccountPage}
            />
          </React.Suspense>
        </Switch>

        {/* UpdateAlert */}
        <Snackbar
          open={serviceWorker.updated}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            elevation={6}
            severity="warning"
            className="alert-default"
            action={
              <Button
                color="primary"
                size="small"
                onClick={updateServiceWorker}
              >
                Atualizar
              </Button>
            }
          >
            Há uma nova versão do aplicativo disponível.
          </Alert>
        </Snackbar>

        {/* SharedDocumentAlert */}
        <Snackbar
          open={alertLogBefore}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          onClose={handleCloseLogBeforeAlert}
        >
          <Alert
            elevation={6}
            severity="warning"
            className="alert-default"
            onClose={handleCloseLogBeforeAlert}
          >
            Você precisa entrar com uma conta existente ou se registrar antes de
            acessar a página.
          </Alert>
        </Snackbar>

        {/* ErrorAlert */}
        <DefaultAlert
          open={errorAlert}
          text={
            <>
              Ops... Algo deu errado. Se o erro persistir, fale conosco em:{" "}
              <a href="mailto:contato@sociilaw.com" style={{ color: "#fff" }}>
                contato@sociilaw.com
              </a>
            </>
          }
          setOpen={handleCloseAlert}
          variant="filled"
          severity="error"
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />

        {/* New releases */}
        <NewReleases
          user={user}
          activeTutorial={activeTutorial}
          toggleDrawer={toggleDrawerQA}
        />

        {classGrid.length ? (
          <div
            className="navbar-grid"
            style={
              navbarBackground
                ? navbarBackground === "backdrop"
                  ? { background: "rgba(0, 0, 0, 0.5)" }
                  : null
                : null
            }
          >
            {!matchesDesktop &&
            (classGrid === "grid" || classGrid === "grid-with-tabs") ? (
              <QuickActions open={openQA} toggleDrawer={toggleDrawerQA} />
            ) : null}
            <Navbar value={value} setValue={setValue} />
            <AddSign />
          </div>
        ) : null}
      </div>
    </Router>
  );
}
