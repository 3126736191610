import React from "react";

import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    position: "absolute",
    width: "100%",
  },
  alert: {
    borderRadius: 25,
  },
}));

export default function DefaultAlert(props) {
  const classes = useStyles();
  const {
    open,
    setOpen,
    variant,
    severity,
    autoHideDuration,
    anchorOrigin,
    text,
  } = props;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleSnackbarClose}
      autoHideDuration={autoHideDuration}
      className={classes.snackbar}
    >
      <Alert
        onClose={handleSnackbarClose}
        elevation={6}
        variant={variant}
        severity={severity}
        className={classes.alert}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}
