import React from "react";

import {
  Grid,
  CircularProgress,
  Fade,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TransitionGroup } from "react-transition-group";
import {
  phrasesAutomation,
  phrasesReadingDoc,
  phrasesSignature,
  phrasesSettingSign,
} from "../../../constants/variables";

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    height: "30vh",
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: "3.5rem",
    color: theme.palette.secondary.grey,
  },
}));

export default function LoadingComponent(props) {
  const classes = useStyles();
  const { type } = props;
  const phrases =
    type === "signature"
      ? phrasesSignature
      : type === "automation"
      ? phrasesAutomation
      : type === "readingDoc"
      ? phrasesReadingDoc
      : type === "settingSign"
      ? phrasesSettingSign
      : phrasesSignature;
  const [index, setIndex] = React.useState(0);
  const [text, setText] = React.useState(phrases.slice(0, 1));

  React.useEffect(() => {
    setTimeout(() => {
      if (index >= phrases.length - 1) {
        setIndex(0);
        setText([phrases[0]]);
      } else {
        setIndex(index + 1);
        setText([phrases[index + 1]]);
      }
    }, 3000);
  }, [index]);

  return (
    <Grid
      item
      xs={10}
      className={classes.progressContainer}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress size={70} color="primary" />
      </Grid>
      <Grid item xs={12}>
        <TransitionGroup exit={false}>
          {text.map((item) => (
            <Fade key={item} timeout={500}>
              <Typography align="center" className={classes.title}>
                {item}...
              </Typography>
            </Fade>
          ))}
        </TransitionGroup>
      </Grid>
    </Grid>
  );
}
