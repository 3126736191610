import { combineReducers } from "redux";
import * as actions from "./actions";

const initialStates = {
  serviceWorker: { updated: false, registration: null },
  errorAlert: false,
  user: {},
  servicesList: [],
  exampleCards: [],
  partColors: [],
  dataList: [],
  userCards: [],
  userTransactions: [],
  tutorials: [],
  activeTutorial: null,
  emailToVerify: "",
  agreePopup: { open: false, credential: null },
  openAddSign: false,
  verifyEmailPopup: false,
  automationFill: [],
  signatureCreation: {},
  signatureSession: {},
  partsToShare: [],
  changePayPopup: false,
  mentoringLevels: [],
  mentoringLessons: [],
  mentoringBadges: [],
  mentoringBenefits: [],
  gamification: [],
  customAutomations: [],
  customServices: [],
  creditsPage: {
    creditSelected: null,
    paymentMethod: null,
    installments: 1,
  },
  paymentPage: {
    paymentMethod: null,
    installments: 1,
  },
  plansPage: {
    planSelected: null,
    paymentMethod: null,
    installments: 1,
  },
  activatedCodePopup: { open: false },
  settingSign: {
    activeStep: 0,
    signerDialog: {
      open: false,
      email: "",
      name: "",
      cpf: "",
      birthDate: "",
      color: "",
      id: null,
      partSynced: null,
      partType: "",
      errorBirthDate: false,
      errorEmail: false,
      errorCPF: false,
    },
  },
  navbarBackground: null,
  openConfetti: false,
  suggestSignInPopup: false,
  isLoadingPart: false,
  isAddingPart: false,

  pagesScroll: [
    { id: "home", scroll: 0 },
    { id: "history", scroll: 0, index: 0 },
    { id: "history", scroll: 0, index: 1 },
    { id: "history", scroll: 0, index: 2 },
    { id: "history", scroll: 0, index: 3 },
    { id: "history", scroll: 0, index: 4 },
    { id: "services", scroll: 0, index: 0 },
    { id: "services", scroll: 0, index: 1 },
    { id: "services", scroll: 0, index: 2 },
    { id: "services", scroll: 0, index: 3 },
    { id: "services", scroll: 0, index: 4 },
    { id: "services", scroll: 0, index: 5 },
    { id: "services", scroll: 0, index: 6 },
    { id: "share", scroll: 0, index: 0 },
    { id: "share", scroll: 0, index: 1 },
    { id: "wallet", scroll: 0, index: 0 },
    { id: "wallet", scroll: 0, index: 1 },
    { id: "wallet", scroll: 0, index: 2 },
    { id: "mentoring", scroll: 0 },
  ],
  pagesIndex: [
    { id: "services", index: 1 },
    { id: "history", index: 0 },
    { id: "share", index: 1 },
  ],
  acceptTermsDialog: false,
};

const appReducer = combineReducers({
  serviceWorker(serviceWorker = initialStates.serviceWorker, action) {
    switch (action.type) {
      case actions.SW_UPDATE:
        return { updated: true, registration: action.payload };
      default:
        return serviceWorker;
    }
  },
  errorAlert(errorAlert = initialStates.errorAlert, action) {
    switch (action.type) {
      case actions.SET_ERROR_ALERT:
        return action.state;
      default:
        return errorAlert;
    }
  },
  user(user = initialStates.user, action) {
    switch (action.type) {
      case actions.SET_USER:
        return { ...action.user };
      default:
        return user;
    }
  },
  userCards(userCards = initialStates.userCards, action) {
    switch (action.type) {
      case actions.SET_USER_CARDS:
        return action.userCards;
      default:
        return userCards;
    }
  },
  userTransactions(userTransactions = initialStates.userTransactions, action) {
    switch (action.type) {
      case actions.SET_USER_TRANSACTIONS:
        return action.userTransactions;
      default:
        return userTransactions;
    }
  },
  servicesList(servicesList = initialStates.servicesList, action) {
    switch (action.type) {
      case actions.SET_SERVICES_LIST:
        return action.servicesList;
      default:
        return servicesList;
    }
  },
  exampleCards(exampleCards = initialStates.exampleCards, action) {
    switch (action.type) {
      case actions.SET_EXAMPLE_CARDS:
        return action.exampleCards;
      default:
        return exampleCards;
    }
  },
  dataList(dataList = initialStates.dataList, action) {
    switch (action.type) {
      case actions.SET_DATA_LIST:
        return action.dataList;
      default:
        return dataList;
    }
  },
  tutorials(tutorials = initialStates.tutorials, action) {
    switch (action.type) {
      case actions.SET_TUTORIALS:
        return action.tutorials;
      default:
        return tutorials;
    }
  },
  activeTutorial(activeTutorial = initialStates.activeTutorial, action) {
    switch (action.type) {
      case actions.SET_ACTIVE_TUTORIAL:
        return action.activeTutorial;
      case actions.ADD_REF_AT:
        return { ...activeTutorial, ref: action.ref };
      default:
        return activeTutorial;
    }
  },
  emailToVerify(emailToVerify = initialStates.emailToVerify, action) {
    switch (action.type) {
      case actions.SET_EMAIL_TO_VERIFY:
        return action.email;
      default:
        return emailToVerify;
    }
  },
  agreePopup(agreePopup = initialStates.agreePopup, action) {
    switch (action.type) {
      case actions.SET_AGREE_POPUP:
        return action.state;
      case actions.TOGGLE_AGREE_POPUP:
        return { ...agreePopup, open: !agreePopup.open };
      default:
        return agreePopup;
    }
  },
  openAddSign(openAddSign = initialStates.openAddSign, action) {
    switch (action.type) {
      case actions.SET_OPEN_ADD_SIGN:
        return action.state;
      default:
        return openAddSign;
    }
  },
  verifyEmailPopup(verifyEmailPopup = initialStates.verifyEmailPopup, action) {
    switch (action.type) {
      case actions.SET_VERIFY_EMAIL_POPUP:
        return action.state;
      default:
        return verifyEmailPopup;
    }
  },
  automationFill(automationFill = initialStates.automationFill, action) {
    switch (action.type) {
      case actions.SET_AUTOMATION_FILL:
        return [...action.state];
      case actions.SET_FIELD_ANSWER_AF:
        let newArray = [...automationFill];
        let newQuestionsArray = [...newArray[action.activePart].questions];
        newQuestionsArray[action.activeStep] = {
          ...newQuestionsArray[action.activeStep],
          [action.field]: action.value,
        };
        newArray[action.activePart] = {
          ...newArray[action.activePart],
          questions: [...newQuestionsArray],
        };
        return newArray;
      default:
        return automationFill;
    }
  },
  signatureCreation(
    signatureCreation = initialStates.signatureCreation,
    action
  ) {
    switch (action.type) {
      case actions.SET_SIGNATURE_CREATION:
        return action.object;
      case actions.ADD_PART_SC:
        return {
          ...signatureCreation,
          parts: [...signatureCreation.parts, action.part],
        };
      case actions.EDIT_PART_SC:
        return {
          ...signatureCreation,
          parts: signatureCreation.parts.map((part) => {
            if (part.id === action.part.id) {
              return action.part;
            }
            return part;
          }),
        };
      case actions.REMOVE_PART_SC:
        const partToRemove = signatureCreation.parts.find(
          (part) => part.id === action.id
        );
        let partsRemoved = signatureCreation.parts.filter(
          (part) => part.id !== action.id
        );
        if (partToRemove.active) {
          partsRemoved = partsRemoved.map((part, i) =>
            i === partsRemoved.length - 1 ? { ...part, active: true } : part
          );
        }
        return { ...signatureCreation, parts: partsRemoved };
      case actions.ADD_FIELD_SC:
        const fieldsNotActive = signatureCreation.fields.map((field) => ({
          ...field,
          active: false,
        }));
        return {
          ...signatureCreation,
          fields: [...fieldsNotActive, action.field],
        };
      case actions.EDIT_FIELD_SC:
        return {
          ...signatureCreation,
          fields: signatureCreation.fields.map((field) => {
            if (field.id === action.field.id) {
              return action.field;
            }
            return field;
          }),
        };
      case actions.REMOVE_FIELD_SC:
        return {
          ...signatureCreation,
          fields: signatureCreation.fields.filter(
            (field) => field.id !== action.id
          ),
        };
      case actions.ADD_PART_RELATED_SC:
        return {
          ...signatureCreation,
          parts: signatureCreation.parts.map((part) => {
            if (part.id === action.id) {
              return { ...part, partRelated: action.partRelated };
            }
            return part;
          }),
          fields: signatureCreation.fields
            .filter((field) => {
              if (field.partId === action.id) {
                return action.partRelated[field.type];
              }
            })
            .map((field) => {
              if (field.partId === action.id) {
                if (field.type === "signature" || field.type === "rubric") {
                  return {
                    ...field,
                    img: action.partRelated[field.type].dataURL,
                    trimmedImg:
                      action.partRelated[field.type].trimmedDataURL || null,
                    isFile: action.partRelated[field.type].trimmedDataURL
                      ? false
                      : true,
                  };
                }
                return { ...field, text: action.partRelated[field.type] };
              }
              return field;
            }),
        };
      case actions.REMOVE_PART_RELATED_SC:
        return {
          ...signatureCreation,
          parts: signatureCreation.parts.map((part) => {
            if (part.id === action.id) {
              return { ...part, partRelated: null };
            }
            return part;
          }),
        };
      case actions.ADD_PART_TO_RELATE_SC:
        return {
          ...signatureCreation,
          partToRelate: action.partToRelate,
        };
      case actions.REMOVE_PART_TO_RELATE_SC:
        return {
          ...signatureCreation,
          partToRelate: null,
        };
      case actions.ADD_PART_RELATED_TO_RELATE_SC:
        return {
          ...signatureCreation,
          partToRelate: {
            ...signatureCreation.partToRelate,
            partRelated: action.partRelated,
          },
        };
      default:
        return signatureCreation;
    }
  },
  partColors(partColors = initialStates.partColors, action) {
    switch (action.type) {
      case actions.SET_PART_COLORS:
        return action.partColors;
      default:
        return partColors;
    }
  },
  signatureSession(signatureSession = initialStates.signatureSession, action) {
    switch (action.type) {
      case actions.SET_SIGNATURE_SESSION:
        return { ...action.signatureSession };
      default:
        return signatureSession;
    }
  },
  partsToShare(partsToShare = initialStates.partsToShare, action) {
    switch (action.type) {
      case actions.SET_PARTS_TO_SHARE:
        return action.parts;
      case actions.ADD_PART_TO_SHARE:
        return [...partsToShare, action.part];
      case actions.REMOVE_PART_TO_SHARE:
        return partsToShare.filter((part) => part.type !== action.partType);
      case actions.CHANGE_PART_TO_SHARE_FIELD:
        let partToChange = partsToShare.find((p) => p.type === action.partType);
        const filteredParts = partsToShare.filter(
          (p) => p.type !== action.partType
        );
        partToChange[action.field] = action.value;
        return [...filteredParts, partToChange];
      default:
        return partsToShare;
    }
  },
  changePayPopup(changePayPopup = initialStates.changePayPopup, action) {
    switch (action.type) {
      case actions.SET_CHANGE_PAY_POPUP:
        return action.state;
      default:
        return changePayPopup;
    }
  },
  navbarBackground(navbarBackground = initialStates.navbarBackground, action) {
    switch (action.type) {
      case actions.SET_NAVBAR_BACKGROUND:
        return action.state;
      default:
        return navbarBackground;
    }
  },
  pagesScroll(pagesScroll = initialStates.pagesScroll, action) {
    switch (action.type) {
      case actions.SET_PAGES_SCROLL:
        return action.array;
      case actions.ON_SCROLL:
        const updatedPagesScroll = pagesScroll.map((ps) => {
          if (ps.index !== undefined) {
            if (ps.index === action.index && ps.id === action.id) {
              return { ...ps, scroll: action.scroll };
            }
          } else if (ps.id === action.id) {
            return { ...ps, scroll: action.scroll };
          }
          return ps;
        });
        return updatedPagesScroll;
      default:
        return pagesScroll;
    }
  },
  pagesIndex(pagesIndex = initialStates.pagesIndex, action) {
    switch (action.type) {
      case actions.SET_PAGES_INDEX:
        return action.array;
      case actions.CHANGE_PAGE_INDEX:
        const updatedPagesIndex = pagesIndex.map((pi) => {
          if (pi.id === action.id) {
            return { ...pi, index: action.index };
          }
          return pi;
        });
        return updatedPagesIndex;
      default:
        return pagesIndex;
    }
  },
  mentoringLevels(mentoringLevels = initialStates.mentoringLevels, action) {
    switch (action.type) {
      case actions.SET_MENTORING_LEVELS:
        return action.array;
      default:
        return mentoringLevels;
    }
  },
  mentoringLessons(mentoringLessons = initialStates.mentoringLessons, action) {
    switch (action.type) {
      case actions.SET_MENTORING_LESSONS:
        return action.array;
      default:
        return mentoringLessons;
    }
  },
  mentoringBadges(mentoringBadges = initialStates.mentoringBadges, action) {
    switch (action.type) {
      case actions.SET_MENTORING_BADGES:
        return action.array;
      default:
        return mentoringBadges;
    }
  },
  mentoringBenefits(
    mentoringBenefits = initialStates.mentoringBenefits,
    action
  ) {
    switch (action.type) {
      case actions.SET_MENTORING_BENEFITS:
        return action.array;
      default:
        return mentoringBenefits;
    }
  },
  gamification(gamification = initialStates.gamification, action) {
    switch (action.type) {
      case actions.SET_GAMIFICATION:
        return action.array;
      case actions.ADD_POPUP_GAMIFICATION:
        return [...gamification, action.object];
      case actions.REMOVE_POPUP_GAMIFICATION:
        return gamification.filter((popup) => popup.type !== action.name);
      default:
        return gamification;
    }
  },
  activatedCodePopup(
    activatedCodePopup = initialStates.activatedCodePopup,
    action
  ) {
    switch (action.type) {
      case actions.SET_ACTIVATED_CODE_POPUP:
        return action.state;
      default:
        return activatedCodePopup;
    }
  },
  openConfetti(openConfetti = initialStates.openConfetti, action) {
    switch (action.type) {
      case actions.SET_OPEN_CONFETTI:
        return action.state;
      default:
        return openConfetti;
    }
  },
  suggestSignInPopup(
    suggestSignInPopup = initialStates.suggestSignInPopup,
    action
  ) {
    switch (action.type) {
      case actions.SET_SUGGEST_SIGN_IN_POPUP:
        return action.state;
      default:
        return suggestSignInPopup;
    }
  },
  customAutomations(
    customAutomations = initialStates.customAutomations,
    action
  ) {
    switch (action.type) {
      case actions.SET_CUSTOM_AUTOMATIONS:
        return action.state;
      default:
        return customAutomations;
    }
  },
  customServices(customServices = initialStates.customServices, action) {
    switch (action.type) {
      case actions.SET_CUSTOM_SERVICES:
        return action.state;
      default:
        return customServices;
    }
  },
  creditsPage(creditsPage = initialStates.creditsPage, action) {
    switch (action.type) {
      case actions.SET_CREDITS_PAGE:
        return action.state;
      case actions.RESET_CREDITS_PAGE:
        return initialStates.creditsPage;
      case actions.SET_CREDIT_SELECTED:
        return { ...creditsPage, creditSelected: action.credit };
      case actions.SET_CREDIT_PAYMENT_METHOD:
        return { ...creditsPage, paymentMethod: action.paymentMethod };
      case actions.SET_CREDIT_INSTALLMENTS:
        return { ...creditsPage, installments: action.installments };
      default:
        return creditsPage;
    }
  },
  paymentPage(paymentPage = initialStates.paymentPage, action) {
    switch (action.type) {
      case actions.SET_PAYMENT_PAGE:
        return action.state;
      case actions.RESET_PAYMENT_PAGE:
        return initialStates.paymentPage;
      case actions.SET_PAY_PAYMENT_METHOD:
        return { ...paymentPage, paymentMethod: action.paymentMethod };
      case actions.SET_PAYMENT_INSTALLMENTS:
        return { ...paymentPage, installments: action.installments };
      default:
        return paymentPage;
    }
  },
  plansPage(plansPage = initialStates.plansPage, action) {
    switch (action.type) {
      case actions.SET_PLANS_PAGE:
        return action.state;
      case actions.RESET_PLANS_PAGE:
        return initialStates.plansPage;
      case actions.SET_PLAN_SELECTED:
        return { ...plansPage, planSelected: action.plan };
      case actions.SET_PLAN_PAYMENT_METHOD:
        return { ...plansPage, paymentMethod: action.paymentMethod };
      case actions.SET_PLAN_INSTALLMENTS:
        return { ...plansPage, installments: action.installments };
      default:
        return plansPage;
    }
  },
  settingSign(settingSign = initialStates.settingSign, action) {
    switch (action.type) {
      case actions.SET_SETTING_SIGN:
        return { ...settingSign, ...action.state };
      case actions.SET_PART_SYNCED_ON_SETTING_SIGN:
        return {
          ...settingSign,
          signerDialog: {
            ...settingSign.signerDialog,
            ...action.state,
          },
        };
      case actions.RESET_SETTING_SIGN:
        return initialStates.settingSign;
      default:
        return settingSign;
    }
  },
  acceptTermsDialog(
    acceptTermsDialog = initialStates.acceptTermsDialog,
    action
  ) {
    switch (action.type) {
      case actions.SET_ACCEPT_TERMS_DIALOG:
        return action.state;
      default:
        return acceptTermsDialog;
    }
  },
  isLoadingPart(isLoadingPart = initialStates.isLoadingPart, action) {
    switch (action.type) {
      case actions.SET_IS_LOADING_PART:
        return action.state;
      default:
        return isLoadingPart;
    }
  },
  isAddingPart(isAddingPart = initialStates.isAddingPart, action) {
    switch (action.type) {
      case actions.SET_IS_ADDING_PART:
        return action.state;
      default:
        return isAddingPart;
    }
  },
});

export const rootReducer = (state, action) => {
  if (action.type === "SIGN_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
