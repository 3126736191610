import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as ROUTES from "../../../constants/routes";
import { addRefAT } from "../../../store/actions";

import { Backdrop, Drawer, IconButton, makeStyles } from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";

import InterfaceIcon from "../../../Images/interface.svg";
import MenuIcon from "../../../Images/menu.svg";

import SquareButton from "../../Comms/Buttons/Square";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.primary.main,
    height: "100svh",
    borderRadius: "40px 40px 0 0",
    overflow: "hidden",
  },
  listaButtons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 0,
    margin: "5em auto",
    maxWidth: 330,
  },
  ulnp: {
    listStyleType: "none",
    width: "33.3%",
    marginBottom: "1rem",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const Menu = () => {
  const classes = useStyles();

  const [state, setState] = useState(false);

  const handleDrawer = (open) => (e) => {
    setState(open);
  };

  const activeTutorial = useSelector((state) => state.activeTutorial);
  const menuButtonRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (activeTutorial && menuButtonRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "header.menu_button"
      ) {
        const { width, height, top, left } =
          menuButtonRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, menuButtonRef.current]);

  useLayoutEffect(() => {
    if (activeTutorial) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "header.menu"
      ) {
        setState(true);
      } else {
        setState(false);
      }
    }
  }, [activeTutorial]);

  return (
    <>
      <IconButton onClick={handleDrawer(true)} ref={menuButtonRef}>
        <img src={MenuIcon} width="29" alt="Ícone Menu" />
      </IconButton>
      <Drawer
        anchor="bottom"
        open={state}
        className="drawer"
        ModalProps={{
          disableScrollLock: true,
          disableEscapeKeyDown: true,
          hideBackdrop: true,
        }}
        transitionDuration={200}
      >
        <Backdrop open={state} onClick={handleDrawer(false)} />
        <div role="presentation" className={classes.drawer}>
          <ul className={classes.listaButtons}>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.DATA}
                onClick={handleDrawer(false)}
                text={"Dados"}
                icon={
                  <AssessmentOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.TERMS_OF_SERVICE}
                onClick={handleDrawer(false)}
                icon={
                  <AssignmentOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Termos"}
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.PRIVACY_POLICE}
                onClick={handleDrawer(false)}
                icon={
                  <DescriptionOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Política"}
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.FEEDBACK}
                onClick={handleDrawer(false)}
                icon={
                  <FeedbackOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Feedback"}
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.HELP}
                onClick={handleDrawer(false)}
                icon={
                  <InfoOutlinedIcon className={classes.icon} fontSize="large" />
                }
                text={"Ajuda"}
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                disabled={true}
                component="button"
                icon={
                  <SettingsOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Preferências"}
                tooltipExists={true}
                tooltipTitle="Em breve"
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                disabled={true}
                component="button"
                icon={<img src={InterfaceIcon} alt="Interface" />}
                text={"Interface"}
                tooltipExists={true}
                tooltipTitle="Em breve"
              />
            </li>
            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                disabled={true}
                component="button"
                icon={
                  <GroupAddOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Indicações"}
                tooltipExists={true}
                tooltipTitle="Em breve"
              />
            </li>

            <li className={classes.ulnp}>
              <SquareButton
                isWhiteText={true}
                disabled={true}
                component="button"
                icon={
                  <AddBoxOutlinedIcon
                    className={classes.icon}
                    fontSize="large"
                  />
                }
                text={"Benefícios"}
                tooltipExists={true}
                tooltipTitle="Em breve"
              />
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default Menu;
