import React from "react";
import { history } from "../../store/history";
import { findGetParameter } from "../../Helpers/SearchParams";

export default function Auth() {
  const mode = findGetParameter("mode");
  const oobCode = findGetParameter("oobCode");

  if (mode === "verifyEmail") {
    history.replace(`/verificacao-do-email-completa?oobCode=${oobCode}`);
  } else if (mode === "resetPassword") {
    history.replace(`/reconfigurar-senha?oobCode=${oobCode}`);
  }

  return <div></div>;
}
