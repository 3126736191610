import React, { useLayoutEffect, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  makeStyles,
  SwipeableDrawer,
  Grid,
  useMediaQuery,
  Fade,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";

import { history } from "../../store/history";
import * as ROUTES from "../../constants/routes";
import {
  changePageIndex,
  onScroll,
  resetSettingSign,
  setSuggestSignInPopup,
  setVerifyEmailPopup,
} from "../../store/actions";
import { addRefAT } from "../../store/actions";

import TouchDrawer from "../../Images/touch_drawer_grey.svg";
import AutomationIcon from "../../Images/automation.svg";

import SquareButton from "../Comms/Buttons/Square";
import SummarizeDoc from "../SummarizeDoc";
import { v4 as uuid } from "uuid";

const drawerBleeding = 125;

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "1299 !important",
  },
  paper: {
    height: 100,
    overflow: "visible",
    zIndex: "1299 !important",
  },
  presentation: {
    zIndex: "1299 !important",
    backgroundColor: theme.palette.secondary.main,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    visibility: "visible",
  },
  gridTouch: {
    height: 28,
  },
  touch: {
    userSelect: "none",
    width: 70,
    opacity: 0.53,
    marginTop: "1rem",
  },
  box: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "25px 25px 0 0",
    height: drawerBleeding + 100,
    position: "absolute",
    top: -drawerBleeding,
    visibility: "visible",
    right: 0,
    left: 0,
    boxShadow: `-10px 0px 3rem -20px ${theme.palette.secondary.grey}`,
  },
  drawerDisplayNone: { display: "none" },
  iconFontSize: { fontSize: "1.8rem" },
  containerBtns: {
    padding: "1rem",
    overflowY: "auto",
    "& .MuiGrid-item": {
      padding: "0 .4rem",
    },
  },
}));

const QuickActions = (props) => {
  const classes = useStyles();
  const { open, toggleDrawer } = props;

  const matchesMobileDevices = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleNewSign = () => {
    if (user.isAnon) {
      dispatch(setSuggestSignInPopup(true));
      return;
    }
    if (!user.emailVerified) {
      dispatch(setVerifyEmailPopup(true));
      return;
    }
    // New sign

    dispatch(resetSettingSign());
    history.push(`/configurar-assinatura/${uuid()}`);
  };

  // Tutorial implementation
  const activeTutorial = useSelector((state) => state.activeTutorial);
  // Creating refs
  const signBtnRef = useRef(null);
  const openDrawerRef = useRef(null);

  useEffect(() => {
    // Only run when is mobile
    if (activeTutorial && openDrawerRef.current && matchesMobileDevices) {
      //  Destructuring component from page to simplify
      const { component } = activeTutorial.pages[activeTutorial.activeIndex];
      if (component === "signature.openDrawer") {
        setTimeout(() => {
          setRef(openDrawerRef);
        }, 300);
      }
    }
  }, [activeTutorial, openDrawerRef.current, matchesMobileDevices]);

  useLayoutEffect(() => {
    if (activeTutorial && matchesMobileDevices) {
      const { component } = activeTutorial.pages[activeTutorial.activeIndex];
      if (component === "signature.sign_button") {
        toggleDrawer(true);
        // Adding setTimeout to wait popup open
        setTimeout(() => {
          setRef(signBtnRef);
        }, 300);
      } else if (
        (open && component === "signature.openDrawer") ||
        component === "signature.addSign_buttons"
      ) {
        toggleDrawer(false);
      } else {
        toggleDrawer(false);
      }
    }
  }, [activeTutorial, matchesMobileDevices]);

  // Creating function to simplify code
  const setRef = (reference) => {
    const { width, height, top, left } =
      reference.current.getBoundingClientRect();
    const ref = { width, height, top, left };

    if (!activeTutorial.ref) {
      dispatch(addRefAT(ref));
    }
  };

  const handleRedirectToAutomations = () => {
    toggleDrawer(false);
    dispatch(onScroll(0, "services", 1));
    dispatch(changePageIndex("services", 1));
    history.push(ROUTES.SERVICES);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <Grid container alignContent="flex-start" className={classes.box}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-start"
          className={classes.gridTouch}
        >
          <img
            src={TouchDrawer}
            alt="Touch"
            className={classes.touch}
            ref={openDrawerRef}
          />
        </Grid>
        <Fade in={open} timeout={100}>
          <Grid
            item
            xs={12}
            container
            wrap="nowrap"
            className={classes.containerBtns}
          >
            <Grid item>
              <SquareButton
                icon={<img src={AutomationIcon} alt="Automações" />}
                text="Automações"
                component="button"
                onClick={handleRedirectToAutomations}
              />
            </Grid>
            <Grid item ref={signBtnRef}>
              <SquareButton
                icon={
                  <EditOutlined
                    color="primary"
                    className={classes.iconFontSize}
                  />
                }
                text="Assinatura"
                onClick={handleNewSign}
                component="button"
              />
            </Grid>
            <Grid item>
              <SummarizeDoc />
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </SwipeableDrawer>
  );
};

export default QuickActions;
