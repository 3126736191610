import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";

import AmpulhetaIcon from "../../Images/ampulheta.svg";
import BalloonIcon from "../../Images/balloon.svg";
import PensamentoIcon from "../../Images/pensamento.svg";
import LupaIcon from "../../Images/UHlupa.svg";
import ContractIcon from "../../Images/contract.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: ".5em auto",
    opacity: (props) => props.opacity || 1,
  },
  img: {
    width: (props) => props.width || "100%",
    marginBottom: "2em",
    opacity: 0.4,
    maxWidth: 140,
    color: (props) =>
      props.textColor === "black"
        ? theme.palette.secondary.darkGrey
        : props.textColor === "white"
        ? theme.palette.secondary.main
        : theme.palette.secondary.darkGrey,
  },
  title: {
    fontSize: 20,
    color: (props) =>
      props.textColor === "black"
        ? theme.palette.secondary.darkGrey
        : props.textColor === "white"
        ? theme.palette.secondary.main
        : theme.palette.secondary.darkGrey,
    fontWeight: 500,
    marginBottom: ".7rem",
  },
  text: {
    fontSize: 16,
    color: (props) =>
      props.textColor === "black"
        ? theme.palette.secondary.darkGrey
        : props.textColor === "white"
        ? theme.palette.secondary.main
        : theme.palette.secondary.darkGrey,
    width: "90%",
    margin: "0 auto",
    maxWidth: 400,
  },
}));

export default function UserHelper(props) {
  const classes = useStyles(props);
  const { text, type, textColor } = props;

  const checkIcons = () => {
    if (type === "empty") {
      if (textColor === "white") {
        return BalloonIcon;
      } else {
        return PensamentoIcon;
      }
    } else if (type === "notReady") {
      return AmpulhetaIcon;
    } else if (type === "serviceNotFound") {
      return LupaIcon;
    } else if (type === "automationNotFound") {
      return ContractIcon;
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={`${classes.root} root-helper`}
    >
      <Grid item>
        <img src={checkIcons()} className={classes.img} alt="Ícone" />
      </Grid>
      <Grid item>
        <Typography
          className={`${classes.title} titulo-helper`}
          gutterBottom
          align="center"
        >
          {type === "empty" && "Procurando alguma coisa?"}
          {type === "notReady" && "Ainda estamos trabalhando nisso!"}
          {type === "serviceNotFound" && "Procurando outro serviço?"}
          {type === "automationNotFound" && "Procurando outro documento?"}
        </Typography>
        <Typography className={`${classes.text} texto-helper`} align="center">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
