import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Menu from "./Menu";
import Wallet from "./Wallet";
import Profile from "./Profile";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "inherit",
    height: "100%",
    boxShadow: `-15px -10px 10px -17px ${theme.palette.secondary.grey}`,
    backgroundColor: theme.palette.bg,
    [theme.breakpoints.down("sm")]: {
      transition: "box-shadow 0.2s ease-in-out",
    },
  },
  toolbar: {
    padding: "0 17.5px",
    height: "100%",
    justifyContent: "space-between",
  },
  shadow: {
    boxShadow: `0px 20px 1rem -16px ${theme.palette.secondary.grey}`,
  },
}));

const Header = () => {
  const classes = useStyles();

  const pagesScroll = useSelector((state) => state.pagesScroll);
  const pageScrollHome = pagesScroll.length
    ? pagesScroll.find((ps) => ps.id === "home")
    : {};
  const scrollHome = pageScrollHome.scroll || 0;
  const pageScrollMentoring = pagesScroll.length
    ? pagesScroll.find((ps) => ps.id === "mentoring")
    : {};
  const scrollMentoring = pageScrollMentoring.scroll || 0;

  return (
    <AppBar
      className={`${classes.appBar} ${
        (scrollHome > 0 &&
          window.location.pathname === "/" &&
          window.innerWidth < 600) ||
        (scrollMentoring > 0 &&
          window.location.pathname === "/mentoria" &&
          window.innerWidth < 600)
          ? classes.shadow
          : null
      }`}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        <Menu />
        <Wallet />
        <Profile />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
