import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { swUpdate } from "./store/actions";
import FirebaseProvider from "./firebase";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6600",
      light: "#FF9966",
      dark: "#CC3300",
      done: "#00CC99",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      lightGrey: "#CCCCCC",
      grey: "#666666",
      darkGrey: "#333333",
      contrastText: "#FF6600",
    },
    facebook: "#4267B2",
    google: "#5093F1",
    bg: "#FDFAF7",
    bg2: "#F0F0F0",
  },
});

setTimeout(
  () =>
    render(
      <>
        <CssBaseline />
        <Provider store={store}>
          <FirebaseProvider>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </FirebaseProvider>
        </Provider>
      </>,
      document.getElementById("root")
    ),
  1000
);

serviceWorkerRegistration.register({
  onUpdate: (reg) => store.dispatch(swUpdate(reg)),
});
reportWebVitals();
