import React from "react";

import { makeStyles, Typography, Grid, Dialog } from "@material-ui/core";

import invalidAction from "../../../../Images/invalid.svg";

import DefaultButton from "../../../../Components/Comms/Buttons/Default";

const useStyles = makeStyles((theme) => ({
  dialogGrid: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2rem",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
      justifyContent: "center",
    },
  },
  dialogText: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "22px",
    color: theme.palette.secondary.grey,
    [theme.breakpoints.down("sm")]: {
      padding: "1rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      lineHeight: "18px",
    },
  },
  divCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom: "1rem",
  },
  linkTermos: {
    textDecoration: "underline",
    fontWeight: 900,
    color: theme.palette.secondary.grey,
  },
  limitDialog: {
    maxWidth: 355,
  },
  checkboxText: {
    fontSize: 14,
    lineHeight: "20px",
  },
  firstDesignation: {
    color: theme.palette.primary,
  },
  secondDesignation: {
    color: theme.palette.primary.done,
  },
  gridItem: {
    padding: "0 .45rem",
  },
  img: {
    width: "100%",
    margin: "0 5.75em",
  },
  description: {
    fontSize: 20,
    color: theme.palette.secondary.grey,
    fontWeight: 500,
    lineHeight: "22px",
  },
  gridPadding: { padding: "9px" },
  imgPadding: { padding: "2em 0" },
}));

export default function WarningCantNegotiatePopup({ open, handler }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handler(false)}
      open={open}
      className={`dialog agree-popup`}
    >
      <Grid
        className={classes.dialogGrid}
        container
        spacing={2}
        alignContent="center"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          spacing={2}
          className={classes.gridPadding}
        >
          <Grid item xs={12} className={classes.img}>
            <img
              src={invalidAction}
              alt="Ação inválida"
              className={classes.imgPadding}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              Arquivos de imagem, pdf ou scaneados, não podem utilizar a
              ferramenta de negociação.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <DefaultButton
            variant="outlined"
            color="primary"
            text="Voltar"
            onClick={handler(false)}
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultButton variant="contained" color="primary" text="Continuar" />
        </Grid>
      </Grid>
    </Dialog>
  );
}
