import React, { useState, useContext } from "react";
import { FirebaseContext } from "../../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { setErrorAlert, setVerifyEmailPopup } from "../../../store/actions";

import {
  Dialog,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import DefaultButton from "../Buttons/Default";
import MailIcon from "../../../Images/mail.svg";
import VerifyCompleteIcon from "../../../Images/verify-complete.svg";
import { sendEmailVerification } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.darkGrey,
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
  },
  gridText: {
    margin: "1rem 0",
    marginLeft: ".7rem",
  },
  icon: {
    width: 180,
    [theme.breakpoints.down("xs")]: {
      width: 150,
    },
  },
  dialogText: {
    fontSize: 20,
    color: theme.palette.secondary.grey,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      maxWidth: "100%",
    },
  },
  email: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  linkReload: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default function VerifyEmailPopup() {
  const classes = useStyles();

  const { auth, api } = useContext(FirebaseContext);

  const dispatch = useDispatch();
  const verifyEmailPopup = useSelector((state) => state.verifyEmailPopup);

  const [isLoading, setIsLoading] = useState(false);

  const resendEmailVerification = () => {
    if (isLoading) return;
    setIsLoading(true);
    var user = auth.currentUser;

    sendEmailVerification(user)
      .then(() => {
        handleClose();
        setIsLoading(false);
        setPopup(true);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        dispatch(setErrorAlert(true));
        api.saveError(e);
      });
  };

  const [popup, setPopup] = useState(false);

  const handlerPopup = (open) => (e) => {
    setPopup(open);
  };

  const handleClose = () => {
    dispatch(setVerifyEmailPopup(false));
  };

  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <>
      <Dialog
        open={verifyEmailPopup}
        onClose={handleClose}
        className="popup-intern"
      >
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} container justifyContent="center">
            <img
              src={MailIcon}
              className={classes.icon}
              alt="Ícone da e-mail"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridText}>
            <Typography className={classes.dialogText}>
              É necessário verificar o seu e-mail para realizar esta ação. Você
              deseja que o e-mail de confirmação seja enviado novamente?
              <br />
              <b>
                Caso já tenha verificado o seu e-mail, basta clicar{" "}
                <span onClick={handleUpdate} className={classes.linkReload}>
                  aqui
                </span>{" "}
                para atualizar a página.
              </b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DefaultButton
              variant="outlined"
              color="primary"
              onClick={handleClose}
              text="Voltar"
            />
          </Grid>
          <Grid item xs={6}>
            <DefaultButton
              variant="contained"
              color="primary"
              onClick={resendEmailVerification}
              text={
                isLoading ? <CircularProgress color="secondary" /> : "Reenviar"
              }
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={popup}
        onClose={handlerPopup(false)}
        className="popup-intern"
      >
        <Grid
          className={classes.root}
          container
          spacing={2}
          alignContent="center"
        >
          <Grid item xs={12} container justifyContent="center">
            <img
              src={VerifyCompleteIcon}
              className={classes.icon}
              alt="Ícone de email enviado"
            />
          </Grid>
          <Grid item xs={12} className={classes.gridText}>
            <Typography align="left" className={classes.dialogText}>
              Pronto! O e-mail de verificação já foi enviado para{" "}
              <span className={classes.email}>{auth.currentUser?.email}</span>.
              Lembre-se de verificar sua caixa de span caso não o encontre.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DefaultButton
              variant="contained"
              color="primary"
              onClick={handlerPopup(false)}
              text="Entendi"
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
