import RocketIcon from "../Images/rocket.svg";
import HomeWorkIcon from "../Images/home-work.svg";
import { getInstallmentValues } from "../Helpers/Payment";

import intro1 from "../Images/intro1.webp";
import intro2 from "../Images/intro2.webp";
import intro3 from "../Images/intro3.webp";
import intro4 from "../Images/intro4.webp";

// PAYMENT
export const PAG_SEGURO_PUBLIC_KEY =
  window.location.hostname === "localhost"
    ? "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB"
    : "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1VcVJ5uXj1EErc9xAoSKAUHl/wo7tiYb6KVJK/CP7UZLs8PnP9ue91mbUQW49D0jPUiK2o8veC56hZzr/FnlB0g5GSneEZovFc7SyvD0CPa4vNqd/gL61jP9GbJE6dRySB5+YV4o4qwt7faLgq7RSe+xejAQ5BxTp5N/YU4ygPy4YndebdJUGKEPjhGbrfLA/Vo+c31nNteBMtA6ZOrctLKnisaIJ5lRgxlfNr0aFRr4pQXY6gvGQcFDCiHnao48v9xBGTVJiVuh0U3G0Qeci6vMcFisgAZ+nvEIUcOdk33j3cGJt3MzCpUPyJECwTk+txbguxrtUmFRcCf74fHFawIDAQAB";
export const creditsPackages = [
  {
    id: "G7fxRfm9Fp01gjqj1d9z",
    color: "red",
    credits: 250,
    price: 237.5,
    values: getInstallmentValues(237.5),
  },
  {
    id: "IPew9VaetsgELaetfWMS",
    color: "orange",
    credits: 1000,
    price: 900,
    values: getInstallmentValues(900),
  },
  {
    id: "a4Nus4hxIVqL6gagn089",
    color: "green",
    credits: 5000,
    price: 4250,
    values: getInstallmentValues(4250),
  },
];

export const plans = [
  {
    id: "0tnqzbAnVUIKgPIMRdAI",
    title: "Startups",
    type: "startups",
    img: <img src={RocketIcon} width="50%" />,
    price: 1800,
    values: getInstallmentValues(1800),
  },
  // {
  //   id: "price_1LL8nRCUo44w5fJVMmps4dAM",
  //   title: "Imobiliárias",
  //   type: "realState",
  //   img: <img src={HomeWorkIcon} width="52%" />,
  //   price: 350,
  //   values: getInstallmentValues(350),
  // },
];

// INTRO
export const introSteps = [
  {
    label:
      "Seja bem vindo(a) a Socii! Aqui nosso objetivo é automatizar o jurídico para reduzir seu custo e democratizar seu acesso, ao máximo de pessoas que conseguirmos.",
    img: intro1,
  },
  {
    label:
      "Na plataforma você tem acesso gratuito a documentos automatizados, uma ferramenta  de assinatura e negociação, e claro,  consegue acompanhar todos os seus serviços jurídicos e registros.",
    img: intro2,
  },
  {
    label:
      "A Socii foi criada pensando no mundo moderno. Queremos trazer novas soluções jurídicas para os empreendedores, mais rápidas, mais baratas e mais eficientes.",
    img: intro3,
  },
  {
    label:
      "Estamos trabalhando para solucionar o máximo de problemas do jurídico moderno e trazer a advocacia para a nova revolução. Então, espere novidades e mudanças constantes na plataforma.",
    img: intro4,
  },
];

// LOADING
export const phrasesAutomation = [
  "Analisando suas respostas",
  "Substituindo os campos necessários",
  "Construindo seu documento",
  "Salvando o documento",
];

export const phrasesSignature = [
  "Adicionando os campos necessários",
  "Construindo seu documento assinado",
  "Salvando o documento assinado",
];

export const phrasesReadingDoc = [
  "Lendo o documento",
  "Extraindo texto",
  "Estimando tempo",
  "Verificando custo",
];

export const phrasesSettingSign = [
  "Salvando documento",
  "Mandando para signatários",
  "Enviando para assinatura",
];
