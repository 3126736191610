import React, { useRef, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { addRefAT } from "../../store/actions";
import * as ROUTES from "../../constants/routes";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import { SchoolOutlined, WorkOutlineOutlined } from "@material-ui/icons";
import WorkHistoryIcon from "../../Images/work-history.svg";

const useStyles = makeStyles((theme) => ({
  navbar: {
    height: "100%",
    borderRadius: "0 35px 35px 0",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 1300,
    backgroundColor: theme.palette.primary.main,
    padding: "0 1rem",
    boxShadow: `0px 0px .5em ${theme.palette.secondary.grey}`,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: "100%",
      borderRadius: "25px 25px 0 0",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      boxShadow: `0 0 .5em ${theme.palette.secondary.grey}`,
    },
  },
  icons: {
    color: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "28px",
      minWidth: "0",
    },
  },
  historyIcon: {
    width: 35,
    height: 35,
  },
  negotiationsImg: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  visible: {
    zIndex: 1301,
  },
}));

const NavBar = ({ value, setValue }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:960px)");

  // Template - tutorial_highlight_implementation
  const activeTutorial = useSelector((state) => state.activeTutorial);
  const homeRef = useRef();
  const servicesRef = useRef();
  const shareRef = useRef();
  const historyRef = useRef();
  const mentorRef = useRef();
  const navBarRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTutorial && servicesRef.current && value === "/") {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "navbar.services" ||
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "navbar.avatar_tour"
      ) {
        const { width, height, top, left } =
          servicesRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, servicesRef.current, value]);

  useLayoutEffect(() => {
    if (activeTutorial && navBarRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "navbar.avatar_services" ||
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "navbar.tour_shareIcon" ||
        activeTutorial.pages[activeTutorial.activeIndex].component === "navbar"
      ) {
        const { width, height, top, left } =
          navBarRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, navBarRef.current]);

  useEffect(() => {
    if (activeTutorial && homeRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "navbar.home"
      ) {
        const { width, height, top, left } =
          homeRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, homeRef.current]);

  useEffect(() => {
    if (activeTutorial && navBarRef.current && value === "/") {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "navbar.component"
      ) {
        const { width, height, top, left } =
          navBarRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, navBarRef.current, value]);

  useLayoutEffect(() => {
    if (activeTutorial && shareRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "navbar.share"
      ) {
        const { width, height, top, left } =
          shareRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, shareRef.current]);

  useLayoutEffect(() => {
    if (activeTutorial && historyRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "navbar.history"
      ) {
        const { width, height, top, left } =
          historyRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, historyRef.current, value]);

  useLayoutEffect(() => {
    if (activeTutorial && mentorRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "navbar.mentor"
      ) {
        const { width, height, top, left } =
          mentorRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, mentorRef.current]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels={matches ? true : false}
      className={classes.navbar}
      ref={navBarRef}
    >
      <BottomNavigationAction
        component={Link}
        to={ROUTES.HOME}
        label="Home"
        value={ROUTES.HOME}
        icon={<HomeOutlinedIcon fontSize="large" className={classes.icons} />}
      />
      <BottomNavigationAction
        component={Link}
        classes={{
          root: classes.visible,
        }}
        to={ROUTES.SERVICES}
        label="Serviços"
        value={ROUTES.SERVICES}
        icon={
          <WorkOutlineOutlined
            fontSize="large"
            className={classes.icons}
            ref={servicesRef}
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to={ROUTES.SHARE}
        value={ROUTES.SHARE}
        label="Compartilhar"
        icon={
          <GroupOutlinedIcon
            fontSize="large"
            className={classes.icons}
            ref={shareRef}
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to={ROUTES.HISTORY}
        label="Histórico"
        value={ROUTES.HISTORY}
        icon={
          <img
            src={WorkHistoryIcon}
            className={`${classes.icons} ${classes.historyIcon}`}
            ref={historyRef}
          />
        }
      />
      <BottomNavigationAction
        component={Link}
        to={ROUTES.MENTORING}
        label="Mentoria"
        value={ROUTES.MENTORING}
        icon={
          <SchoolOutlined
            fontSize="large"
            className={classes.icons}
            ref={mentorRef}
          />
        }
      />
    </BottomNavigation>
  );
};

export default NavBar;
