import React, { useContext, useRef, useState } from "react";

import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";

import ProfileIcon from "../../Images/perfil.svg";
import {
  AttachFileOutlined,
  CallOutlined,
  Close,
  Description,
  DescriptionOutlined,
  GetAppRounded,
  MicNoneOutlined,
  MoreHoriz,
  SearchOutlined,
  SendOutlined,
} from "@material-ui/icons";
import { useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useSelector } from "react-redux";
import DefaultField from "../Comms/Fields/TextField";

import LogoIcon from "../../Images/main-logo.svg";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100svh - 202.55px)",
    position: "relative",
  },
  topBar: {
    height: 80,
    padding: "1rem",
    backgroundColor: theme.palette.secondary.main,
  },
  chat: {
    padding: ".3rem 1rem",
    backgroundColor: theme.palette.bg,
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  bottomBar: {
    height: 80,
    padding: "1rem .5rem",
    backgroundColor: theme.palette.secondary.main,
  },
  userName: {
    fontSize: 18,
    color: theme.palette.secondary.grey,
  },
  divider: {
    height: "60%",
  },
  rootInput: {
    borderRadius: 15,
  },
  input: {
    padding: "1rem",
  },
  message: {
    backgroundColor: "rgba(0,0,0,.1)",
    color: theme.palette.secondary.darkGrey,
    padding: ".3rem .5rem",
    borderRadius: 13,
    display: "flex",
    alignItems: "flex-end",
    maxWidth: 330,
  },
  messageText: {
    fontSize: 12.5,
    fontWeight: 300,
  },
  messageTime: {
    fontSize: 10,
    marginLeft: ".3rem",
  },
  userMessage: {
    backgroundColor: theme.palette.primary.light,
  },
  descriptionIcon: {
    marginRight: ".4rem",
  },
  iconButton: {
    padding: 5,
    margin: "0 .4rem",
  },
  searchPaper: {
    borderRadius: 15,
    position: "absolute",
    top: 0,
    left: "5%",
    width: "90%",
    padding: ".5rem",
    zIndex: 1,
  },
  logoIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: ".3rem",
    marginRight: ".4rem",
    width: 30,
    height: 30,
    borderRadius: "50%",
  },
  initMessage: {
    fontSize: 12,
    fontWeight: 200,
    color: theme.palette.secondary.grey,
  },
  initLogo: {
    backgroundColor: theme.palette.primary.main,
    padding: ".2rem",
    marginRight: ".3rem",
    width: 18,
    height: 18,
    borderRadius: "50%",
    opacity: 0.5,
  },
  messagesMargin: {
    margin: ".5rem 0 0 35px",
  },
  firstMessage: {
    borderTopLeftRadius: 0,
  },
}));

export default function Chat() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const user = useSelector((state) => state.user);

  const isFromUser = (message) => message.uid === user.uid;

  const [chatId, setChatId] = useState("");
  const [chat, setChat] = useState({
    messages: [
      {
        id: 1,
        type: "text",
        content: "Olá! A Socii está trazendo novidades...",
      },
      {
        id: 2,
        type: "text",
        content:
          "Neste local você conseguirá contactar nosso suporte e nossos especialistas via chat",
      },
      {
        id: 3,
        type: "text",
        content: "Colocamos essa novidade aqui pra você já ir se acostumando",
      },
      {
        id: 4,
        type: "text",
        content: "O Chat estará disponível dentro das próximas semanas",
      },
      {
        id: 5,
        type: "text",
        content:
          "Por enquanto você pode continuar falando conosco em: (11) 95449 6048 ou contato@sociilaw.com",
      },
    ],
  });
  const [newMessage, setNewMessage] = useState("");

  const [isSearching, setIsSearching] = useState(false);
  const [searchField, setSearchField] = useState("");
  const handleChangeSearchField = (e) => {
    setSearchField(e.target.value);
  };
  const handleCloseSearch = () => {
    setIsSearching(false);
    setSearchField("");
    scrollToLastMessage();
  };

  const filteredMessages = searchField.length
    ? chat.messages.filter((msg) => {
        if (msg.type === "text" && msg.content.includes(searchField)) {
          return true;
        }
        if (msg.type === "file" && msg.name.includes(searchField)) {
          return true;
        }
        return false;
      })
    : null;

  const handleNewMessage = (e) => {
    setNewMessage(e.target.value);
  };

  const scrollToLastMessage = () => {
    latestMessageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  const isTyping = Boolean(newMessage.length);

  const { db, functions } = useContext(FirebaseContext);
  const sendMessage = httpsCallable(functions, "message-send");

  // useEffect(() => {
  //   const ref = doc(db, "chats", chatId);

  //   const unSub = onSnapshot(ref, (snapshot) => {
  //     const data = snapshot.data();
  //     setChat(data);
  //     scrollToLastMessage();
  //   });
  //   return () => {
  //     unSub();
  //   };
  // }, []);

  const handleSendText = (e) => {
    e.preventDefault();

    setNewMessage("");

    sendMessage({ content: newMessage, type: "text", chatId }).catch(
      (error) => {
        console.error(error);
      }
    );
  };

  async function readFile(file) {
    let result = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(e.target.result);
      fileReader.readAsBinaryString(file);
    });

    return result;
  }

  const handleFileChange = async (e) => {
    const filesArray = Array.from(e.target.files);

    if (!filesArray.length) {
      console.warn("There is no file to send");
      return;
    }

    let files = [];
    for (const file of filesArray) {
      const data = await readFile(file);
      files.push({
        name: file.name,
        data,
      });
    }

    inputRef.current.value = "";
    sendMessage({ content: files, type: "file", chatId }).catch((error) => {
      console.error(error);
    });
  };

  const inputRef = useRef(null);
  const latestMessageRef = useRef(null);

  const limitMessage = (string) => {
    if (!string || !string.length) {
      return "";
    }
    const stringLimit = 25;

    if (string.length > stringLimit) {
      return string.substring(0, stringLimit) + "...";
    }
    return string;
  };

  const getMessages = () => {
    return filteredMessages || chat.messages;
  };

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      {/* <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        className={classes.topBar}
      >
        <Grid item xs={7} container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={ProfileIcon} variant="circular" alt="Ícone usuário" />
          </Grid>
          <Grid item>
            <Typography className={classes.userName}>Daniel Guedes</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          container
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <IconButton>
              <CallOutlined color="primary" />
            </IconButton>
          </Grid>
          <Divider orientation="vertical" className={classes.divider} />
          <Grid item>
            {isSearching ? (
              <Paper elevation={3} className={classes.searchPaper}>
                <DefaultField
                  type="string"
                  label="Pesquisar"
                  value={searchField}
                  onChange={handleChangeSearchField}
                  endAdornment={
                    <IconButton onClick={handleCloseSearch}>
                      <Close />
                    </IconButton>
                  }
                  shrink={true}
                />
              </Paper>
            ) : (
              <IconButton onClick={() => setIsSearching(true)}>
                <SearchOutlined color="primary" />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <IconButton>
              <MoreHoriz
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>Contact info</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Clear messages</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Delete chat</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        container
        className={`${classes.chat} scroll`}
        alignContent="flex-start"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
        >
          <img src={LogoIcon} className={classes.initLogo} />
          <Typography className={classes.initMessage}>
            SociiLaw entrou no chat
          </Typography>
        </Grid>
        {user.uid && chat.messages && chat.messages.length
          ? getMessages().map((message, i) => (
              <Grid
                item
                xs={12}
                container
                justifyContent={isFromUser(message) ? "flex-end" : "flex-start"}
                alignItems="center"
                key={message.id}
                ref={
                  i === chat.messages.length - 1 ? latestMessageRef : undefined
                }
              >
                {i === 0 && <img src={LogoIcon} className={classes.logoIcon} />}
                <Paper
                  elevation={0}
                  className={`${classes.message} ${
                    isFromUser(message) ? classes.userMessage : null
                  } ${i !== 0 ? classes.messagesMargin : classes.firstMessage}`}
                >
                  {message.type === "text" && (
                    <Typography className={classes.messageText}>
                      {message.content}
                      {/* <span className={classes.messageTime}>
                        {message.createdAt
                          .toDate()
                          .toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </span> */}
                    </Typography>
                  )}
                  {message.type === "file" && (
                    <>
                      <Grid container alignItems="center">
                        <Description
                          color="secondary"
                          fontSize="large"
                          className={classes.descriptionIcon}
                        />
                        <Typography>{limitMessage(message.name)}</Typography>
                        <IconButton
                          href={message.downloadURL}
                          target="_blank"
                          className={classes.iconButton}
                        >
                          <GetAppRounded color="secondary" fontSize="large" />
                        </IconButton>
                      </Grid>
                      <span className={classes.messageTime}>
                        {message.createdAt
                          .toDate()
                          .toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </span>
                    </>
                  )}
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.bottomBar}
        component="form"
        onSubmit={handleSendText}
      >
        <Grid item>
          {/* <input
            id="button-file"
            ref={inputRef}
            type="file"
            // onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor="button-file">
            <IconButton component="span" disabled={true}>
              <AttachFileOutlined />
            </IconButton>
          </label> */}
          <IconButton component="span" disabled={true}>
            <AttachFileOutlined />
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="filled"
            placeholder="Escreva uma mensagem"
            fullWidth
            className={classes.textField}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.rootInput,
                input: classes.input,
              },
            }}
            value={newMessage}
            onChange={handleNewMessage}
            disabled={true}
          />
        </Grid>
        <Grid item>
          {/* {isTyping ? (
            <IconButton type="submit">
              <SendOutlined color="primary" />
            </IconButton>
          ) : (
            <IconButton>
              <MicNoneOutlined color="primary" />
            </IconButton>
          )} */}
          <IconButton type="submit" disabled={true}>
            <SendOutlined />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
