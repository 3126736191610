import React, { useEffect } from "react";
import { Grid, Typography, Dialog, makeStyles } from "@material-ui/core";
import DefaultButton from "../Buttons/Default";

import { useSelector, useDispatch } from "react-redux";
import GiftIcon from "../../../Images/gift.svg";
import { setActivatedCodePopup, setOpenConfetti } from "../../../store/actions";
import CompleteAudio from "../../../Audios/complete.mp3";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 19,
    fontWeight: 500,
    color: "#707070",
  },
  paper: {
    padding: "2rem",
    maxWidth: 400,
    borderRadius: 29,
  },
  btnsContainer: {
    maxHeight: 220,
    overflowY: "auto",
  },
}));

export default function ActivatedCodePopup() {
  const classes = useStyles();

  const activatedCodePopup = useSelector((state) => state.activatedCodePopup);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setActivatedCodePopup({ open: false }));
  };

  const { type, credits } = activatedCodePopup.benefits
    ? activatedCodePopup.benefits[0]
    : {};

  useEffect(() => {
    if (activatedCodePopup.open) {
      dispatch(setOpenConfetti(true));
      const completeAudio = new Audio(CompleteAudio);
      completeAudio.play();
    }
  }, [activatedCodePopup]);

  return (
    <Dialog
      open={activatedCodePopup.open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} container justifyContent="center">
          <img src={GiftIcon} width={150} alt="Ícone de presente" />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.text} align="center">
            Pronto! Seu código foi ativado e{" "}
            {type === "credits" &&
              `${credits} créditos foram aplicados na
            sua conta. Agora é só aproveitar!`}
            {type === "tshirt" &&
              `você ganhou uma camiseta! Agora você deve ir até o nosso stand no RIW para resgatar seu prêmio.`}
            {type === "services" &&
              `você ganhou um Registro de Marca! Agora é só aproveitar!`}
            {type === "plan" &&
              `você ganhou um ano de acesso ao SociiLaw+ Startups! Agora é só aproveitar!`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DefaultButton
            color="primary"
            variant="contained"
            text="Vamos lá!"
            onClick={handleClose}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
