export const INTRO = "/introducao";
export const SIGN_IN = "/entrar";
export const SIGN_UP = "/registrar";
export const SIGN_UP_PROMO_CODE = "/registrar/:promoCode";
export const TERMS_OF_SERVICE_INTRO = "/termos-de-uso-introducao";
export const PRIVACY_POLICE_INTRO = "/politica-de-privacidade-introducao";

export const FORGOT_PASSWORD = "/esqueceu-a-senha";
export const VERIFY_EMAIL = "/verificar-email";
export const RECONFIGURE_PASSWORD = "/reconfigurar-senha";
export const VERIFY_COMPLETE = "/verificacao-completa";

export const TERMS_OF_SERVICE = "/termos-de-uso";
export const PRIVACY_POLICE = "/politica-de-privacidade";

export const FEEDBACK = "/feedback";
export const COMPLETED_FEEDBACK = "/feedback-concluido";

export const HOME = "/";
export const SERVICES = "/servicos";
export const HISTORY = "/historico";
export const SHARE = "/compartilhar";
export const MENTORING = "/mentoria";

export const SCORE = "/pontuação";
export const LESSON = "/aula/:id";
export const LESSON_ITEM = "/aula/:lessonId/item/:id";

export const SERVICE = "/servico/:id";
export const REQUEST = "/requisicao/:requestId";
export const ACTION_PLAN = "/plano-de-acao/:actionPlanId";
export const BUDGET = "/plano-de-acao/:actionPlanId/orcamento/:budgetId";
export const STEP_IN_AP = "/plano-de-acao/:actionPlanId/etapa/:stepId";
export const TASK_IN_AP =
  "/plano-de-acao/:actionPlanId/etapa/:stepId/tarefa/:taskId";
export const STEP = "/requisicao/:requestId/etapa/:stepId";
export const TASK = "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId";
export const TASK_WITH_UID =
  "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId/user/:uid";

export const OLD_DOCUMENT =
  "/requisicao/:requestId/etapa/:stepId/documento/:deliveryId";
export const OLD_REGISTRY =
  "/requisicao/:requestId/etapa/:stepId/registro/:deliveryId";
export const DOCUMENT =
  "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId/documento/:deliveryId";
export const AUTOMATION_ISOLATED = "/documento-automatizado/:automationId";
export const SIGNATURE_ISOLATED = "/documento-assinado/:signatureId";
export const DOCUMENT_INSIDE_SIGNATURE =
  "/assinatura/:signatureId/documento/:id";
export const DOCUMENT_INSIDE_SHARED_DOC =
  "/documento-compartilhado/:sharedDocumentId/:id";
export const REGISTRY =
  "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId/registro/:deliveryId";
export const DOCUMENT_ALREADY_FINISHED = "/documento-ja-finalizado";
export const SELECT_CLASSES = "/selecionar-classes/:requestId/:stepId/:taskId";
export const PAYMENT = "/pagamento/:requestId/:stepId/:taskId";

export const DATA = "/dados";

export const INVITE_PAGE = "/compartilhar-convite";
export const INVITE_LIST_PAGE = "/lista-convites";

export const PAYMENT_METHODS = "/meios-de-pagamento";
export const PLANS = "/planos";
export const ADD_METHOD = "/adicionar-cartao";
export const CREDITS = "/creditos";
export const SINGLE_CREDITS = "/creditos/:credits";

export const VERIFY_COMPLETE_INTERN_PAGE = "/verificacao-da-senha-completa";

export const EMAIL_VERIFICATION_COMPLETE_PAGE =
  "/verificacao-do-email-completa";

export const HELP = "/ajuda";
export const FAQ = "/perguntas-frequentes";
export const FAQ_ITEM = "/perguntas-frequentes/:id";
export const TUTORIALS = "/tutoriais";

export const AUTOMATION = "/automacao/:id";
export const FILL_AUTOMATION = "/preencher-automacao/:id";
export const FILL_SHARED_AUTOMATION =
  "/preencher-automacao-compartilhada/:automationId/:sessionId";
export const FINISHED_AUTOMATION = "/automacao-finalizada/:id";
export const FINISHED_SHARED_AUTOMATION =
  "/automacao-compartilhada-finalizada/:sharedDocumentId/:id";
export const SHARED_AUTOMATION = "/automacao-compartilhada/:id";
export const CUSTOM_AUTOMATIONS = "/automacoes-personalizadas";
export const CONTRACT_MODEL = "/sua-automacao/:id";
export const EDIT_CUSTOM_AUTOMATION = "/editar-automacao-personalizada";

export const SHARED_SIGNATURE = "/assinatura-compartilhada/:id";
export const SET_SIGNATURE_PAGE = "/definir-assinatura";
export const SET_SHARED_SIGNATURE_PAGE =
  "/definir-assinatura-compartilhada/:sharedDocumentId/:session";
export const FINISHED_SIGNATURE = "/assinatura-finalizada/:id";
export const FINISHED_SHARED_SIGNATURE =
  "/assinatura-compartilhada-finalizada/:sharedDocumentId/:id";
export const ONLY_SIGN = "/assinar/:uid/:sessionId";
export const SELECT_DOCUMENT_TO_SIGN = "/selecionar-documento-assinar";
export const SELECT_AUTOMATION_TO_SIGN = "/selecionar-automacao-assinar";
export const SHARED_AUTOMATION_USER = "/automacao-compartilhada/:id/:index";
export const SHARED_SIGNATURE_USER = "/assinatura-compartilhada/:id/:index";

// Signature 2.0
export const DRAFTS = "/rascunhos";
export const SETTING_SIGNATURE_2 = "/configurar-assinatura/:draftId";
export const SIGNING_SIGNATURE_2 = "/assinar2/:documentId/:sessionId";
export const SELECT_PART_DRAFT_SIGN =
  "/selecionar-parte-rascunho-assinatura/:draftId";
export const TEST_SIGN2 = "/teste-assinatura2";

export const SELECT_PART =
  "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId/selecionar-parte";
export const SELECT_PARTS =
  "/requisicao/:requestId/etapa/:stepId/tarefa/:taskId/selecionar-partes";
export const WITHOUT_SELECT_PART = "/selecionar-parte";
export const SELECT_PART_SIGNATURE = "/selecionar-parte-assinatura/:signerId";
export const ADD_PART = "/adicionar-parte";
export const EDIT_PART = "/editar-parte/:partId";

export const AUTH = "/auth-action";

export const TAG_EMAIL = "/tag-email";

export const DELETE_ACCOUNT = "/deletar-conta";

export const TEST = "/test";
export const TEST_SUMMARIZE = "/teste-resumir";

export const WHATSAPP_DELETE_DOCUMENT =
  "https://api.whatsapp.com/send?phone=5511964304085&text=Ol%C3%A1%2C%20sou%20cliente%20da%20SociiLaw%20e%20gostaria%20de%20solicitar%20a%20remo%C3%A7%C3%A3o%20de%20um%20documento%20na%20plataforma.";
export const WHATSAPP_PACKAGES =
  "https://api.whatsapp.com/send?phone=556193985580&text=Ol%C3%A1%2C+eu+gostaria+de+saber+mais+sobre+as+op%C3%A7%C3%B5es+de+Planos+da+Socii%2C+voc%C3%AA+poderia+me+ajudar%3F";
export const WHATSAPP_LAWYER =
  "https://api.whatsapp.com/send?phone=556193985580&text=Ol%C3%A1,+Sou+usu%C3%A1rio+da+SociiLaw+e+gostaria+de+falar+com+voc%C3%AA";
